import { Localize } from "utilities/localize";
import { CONFIGURATION } from "utilities/configuration";

function isStep1DataValid(quote, errors) {
    let { groupInformation } = quote;
    let { groupName, groupType, noOfTravellers } = groupInformation;

    errors.groupName = groupName?.trim().length === 0 ? Localize("qma-mandatory") : "";
    errors.groupType = groupType?.trim().length === 0 ? Localize("qma-mandatory") : "";
    if (typeof (noOfTravellers) == "number") {
        errors.noOfTravellers = noOfTravellers?.length === 0 ? Localize("qma-mandatory") :
            (noOfTravellers < 10) ? Localize("qma-group-num-travellers-error-helper-text") : "";
    } else {
        errors.noOfTravellers = noOfTravellers?.trim().length === 0 ? Localize("qma-mandatory") :
            (parseInt(noOfTravellers) < 10) ? Localize("qma-group-num-travellers-error-helper-text") : "";
    }


}

function isGroupNameValid(quote, errors) {
    let { groupInformation } = quote;
    let { groupName } = groupInformation;

    errors.groupName = groupName?.trim().length === 0 ? Localize("qma-mandatory") : "";
}


function isCustomRoomTypeValid(customRoomType, errors, index) {
    errors.customRoomTypes[index] = customRoomType?.trim().length === 0 ? Localize("qma-mandatory") : "";
}

function isGroupTypeValid(quote, errors) {
    let { groupInformation } = quote;
    let { groupType } = groupInformation;

    errors.groupType = groupType?.trim().length === 0 ? Localize("qma-mandatory") : "";
}

function isGroupNumberOfTravellersValid(quote, errors) {
    let { groupInformation } = quote;
    let { noOfTravellers } = groupInformation;

    errors.noOfTravellers = noOfTravellers?.trim().length === 0 ? Localize("qma-mandatory") :
        (parseInt(noOfTravellers) < 10) ? Localize("qma-group-num-travellers-error-helper-text") : "";
}

function isStep2DataValid(quote, errors, index, deviationIndex) {
    let { productTypes, deviationTypes } = quote;
    let { destination, departure, noOfAdults, products, productType, departureDate, returnDate } = productTypes[index];
    let { code } = productType;
    if (code.toLowerCase() !== "deviation") {
        errors.date = (!departureDate || !returnDate) ? Localize("qma-mandatory") : "";
        errors.departureDate = (!departureDate) ? Localize("qma-mandatory") : "";
        errors.returnDate = (!returnDate) ? Localize("qma-mandatory") : "";
    }
    else {
        errors.date = (!deviationTypes[deviationIndex]?.departureDate || !deviationTypes[deviationIndex]?.returnDate) ? Localize("qma-mandatory") : "";
        errors.departureDate = (!deviationTypes[deviationIndex]?.departureDate) ? Localize("qma-mandatory") : "";
        errors.returnDate = (!deviationTypes[deviationIndex]?.returnDate) ? Localize("qma-mandatory") : "";
    }

    if (code.toLowerCase() !== "deviation") {
        errors.destination = (destination?.code === undefined || destination?.code?.trim().length === 0) ? Localize("qma-mandatory") : "";
        if (code.toLowerCase() === "package" || code.toLowerCase() === "flight" || code.toLowerCase() === "cruise") {
            errors.departure = (departure?.code === undefined || departure?.code?.trim().length === 0) ? Localize("qma-mandatory") : "";
        }
        errors.noOfAdults = (noOfAdults === "" || parseInt(noOfAdults) === 0) ? Localize("qma-mandatory") : "";
        if (code.toLowerCase() === "package" || code.toLowerCase() === "hotel" || code.toLowerCase() === "cruise") {
            errors.product = (products?.filter(product => (product.code === undefined || product.code !== ""))?.length < 1) ? Localize("qma-mandatory") : "";
            products?.map((product, index) => {
                if (product?.code !== "") {
                    errors.roomTypes[index] = product?.roomTypes.filter(roomType => roomType?.code !== "")?.length < 1 ? Localize("qma-mandatory") : "";
                    errors.customRoomTypes[index] = product?.roomTypes.filter(roomType => roomType?.code === CONFIGURATION.CUSTOM_ROOM_TYPE_CODE && roomType?.customName === "")?.length >= 1 ? Localize("qma-mandatory") : "";
                }
            });
        }
    } else {
        errors.deviationError.departure = deviationTypes[deviationIndex]?.departures?.length < 1 ? Localize("qma-mandatory") : "";
        errors.deviationError.destination = !deviationTypes[deviationIndex]?.deviation.some((f) => f.destination.code !== "") ? Localize("qma-mandatory") : "";
        errors.deviationError.hotel = !deviationTypes[deviationIndex]?.deviation.some((f) => f.products.some(e => e.code !== "")) ? Localize("qma-mandatory") : "";

        isDeviationRoomTypesValid(deviationTypes, deviationIndex, errors);
    }
}

function isDeviationRoomTypesValid(deviationTypes, deviationIndex, errors) {
    errors.deviationError.rooms = [,,,,,,,,];
    if(deviationTypes) {
        let x = 0;
        deviationTypes[deviationIndex]?.deviation?.forEach(deviation => {
            if(deviation?.products){
                let index = 0;
                
                for (let i = 0; i < deviation?.products.length; i++) {
                    let product = deviation?.products[i];
                    if(product && (!(product.roomTypes && product.roomTypes[0] && product.roomTypes[0].code !== ""))) {
                        errors.deviationError.rooms[x * 4 + index] = Localize("qma-mandatory");
                    }
                    index++;
                }
            }
        });
        x++;
    }

    errors.deviationError.customRoomTypes = [,,,,,,,,];
    if(deviationTypes) {
        let x = 0;
        deviationTypes[deviationIndex]?.deviation?.forEach(deviation => {
            if(deviation?.products){
                let index = 0;
                
                for (let i = 0; i < deviation?.products.length; i++) {
                    let product = deviation?.products[i];

                    if(product && product.roomTypes && product.roomTypes[0] && product.roomTypes[0].code === CONFIGURATION.CUSTOM_ROOM_TYPE_CODE && (product.roomTypes[0].customName === undefined || product.roomTypes[0].customName === null || product.roomTypes[0].customName === "")) {
                        errors.deviationError.customRoomTypes[x * 4 + index] = Localize("qma-mandatory");
                    }
                    index++;
                }
            }
        });
        x++;
    }
}

const validateQuote = {
    isGroupNameValid,
    isGroupTypeValid,
    isGroupNumberOfTravellersValid,
    isStep1DataValid,
    isStep2DataValid,
    isCustomRoomTypeValid,
    isDeviationRoomTypesValid
};

export default validateQuote;
