import React, { Component } from "react";
import Button from "@mui/material/Button";
import { Icon } from "__ICON_REFERENCE__";
import { LocalizeByLang } from "utilities/localize";
import Promotion from "components/common/promotions/promotions";
import commonUtilities from "utilities/common";

import "./promotions.css";
class Promotions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isPromotion: false,
            languageCode: this.props.languageCode ?? commonUtilities.getLanguage()
        };
    }

    localize = (code) => {
        return LocalizeByLang(code, this.state.languageCode);
    };

    showOrHidePromotions = (flag) => {

        window.scrollTo(0, 0);
        document.body.style.overflow = !flag ? "visible" : "hidden";

        let { isPromotion } = this.state;
        isPromotion = flag;
        this.setState({ isPromotion });
    };

    render() {

        let { isPromotion } = this.state;
        const { promotions, isEdit, handleClose } = this.props;
        const forPDF = this.props.forPDF ?? false;

        return (
            <div className="row promotion-wrapper">

                <div className="banner background-dark font-white">

                    <h1 className="font-white margin-bottom">
                        {this.localize("qma-banner")}
                    </h1>
                    <div className="font-24">
                        {this.localize("qma-banner-promotion")}
                    </div>

                    {forPDF ? null : 
                        <div className="show-add-ons margin-top-1x">
                            <Button data-testid="show-all-btn" variant="outlined" onClick={() => this.showOrHidePromotions(true)}>
                                {this.localize("qma-show-all-promotions")}
                            </Button>
                        </div>
                    }

                    {forPDF ? null : 
                        <div className={isPromotion ? "overlay show" : "hide"}>
    
                            <div className="close-btn-row">
                                <Button data-testid="close-btn" variant="default" onClick={() => this.showOrHidePromotions(false)}>
                                    <Icon name="close--n" className="icon font-grey" />
                                </Button>
                            </div>

                            <div className="banner background-dark font-white">
                                <div className="banner-img">

                                </div>
                                <h1 className="font-white">
                                    {this.localize("qma-current-promotions")}
                                </h1>

                            </div>
    
                            <Promotion promotions={promotions} />
                        </div>
                    }
                </div>

                <div className="promotions-container outer-block" data-testid="promotion-list">

                    <Promotion promotions={promotions} isEdit={isEdit} handleClose={handleClose}/>

                </div>

            </div>
        );

    }

}

export default Promotions;