import React, { Component } from "react";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { Section } from "@sunwing/components";
import { Icon } from "__ICON_REFERENCE__";
import ReactHtmlParser from "react-html-parser";

import commonUtilities from "utilities/common";
import { LocalizeByLang } from "utilities/localize";

import Promotion from "components/common/promotions/promotions";
import Email from "../../common/email/email";
import GroupDetails from "../../common/groupDetails/groupDetails";
import Promotions from "./promotions/promotions.js";
import ProductType from "./productType/productType";
import ProductTypeDetails from "./productTypeDetails/productTypeDetails";
import ProfileInformation from "../../common/profileInformation/profileInformation";
import RepresentativeInformation from "../../common/representativeInformation/representativeInformation";
import updateQuoteLetter from "services/quoteLetter/updateQuoteLetter.service";
import quote from "services/quote/quote.service";

import "./index.css";
import AddQuoteOption from "../../common/addQuoteOption/addQuoteOption";
import emailService from "services/email/email.service";

class View extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowEmail: false,
            isTermsAndConditions: false,
            isPricingNotes: false,
            isPromotion: false,
            emails: this.props?.emails?.data?.data?.emailList || [],
            emailListUpdated: false,
            isShowTerms: true,
            cancelTerms: {},
            isEditTerms: false,
            isShowPricing: true,
            isEditPricing: false,
            isShowPromotion: true,
            isEditPromotion: false,
            data: {},
            isEmailSend: false,
            isAlertOn: true,
            termsAndConditions: null,
            languageCode: this.props.languageCode ?? commonUtilities.getLanguage()
        };
    }

    componentDidUpdate() {
        // let { emails, emailListUpdated } = this.state;
        // let emailsList = this.props?.emails?.data?.data?.emailList;
        // if (emailsList && emailsList?.length > 0 && !emailListUpdated) {
        //     let emailItem = [];
        //     emailsList?.map((eachEmail) => {
        //         emailItem.push({ eachEmail });
        //     });
        //     emails = emailItem;
        //     emailListUpdated = true;
        //     this.setState({ emails, emailListUpdated });
        // }
    }

    localize = (code) => {
        return LocalizeByLang(code, this.state.languageCode);
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.quote?.data !== prevState?.data) {
            let newTermAndConditions = [];
            if(nextProps.quote?.data?.quoteNumber !== undefined) {
                if(nextProps.quote?.data?.termsAndConditions?.forEach(element => {
                    newTermAndConditions.push({...element, isEditTerms: false, isShowTerms: true});      
                }));
            }
            return { data: nextProps.quote?.data, termsAndConditions: newTermAndConditions };
        }
        return null;
    }

    handleItinerary = (event, itinerary, isItinerary) => {
        let { quote } = this.props;
        let { quoteOptions } = quote?.data || [];

        quoteOptions.map((quoteOption) => {
            if (quoteOption?.deviationItinerary === null) {
                quoteOption.products.map((product) => {
                    product.itineraries?.map((quoteItinerary) => {
                        if (quoteItinerary.itineraryID === itinerary.itineraryID) {
                            if (isItinerary !== undefined && !isItinerary) {
                                quoteItinerary.isExpanded = !quoteItinerary.isExpanded;
                            } else {
                                quoteItinerary.isExpanded = true;
                                commonUtilities.scrollToItinerary("itinerary_" + itinerary.itineraryID);
                            }
                        }
                    });
                });
            } else {
                if (quoteOption?.deviationItinerary?.itineraryID === itinerary?.itineraryID) {
                    if (isItinerary !== undefined && !isItinerary) {
                        quoteOption.deviationItinerary.isExpanded = !quoteOption.deviationItinerary.isExpanded;
                    } else {
                        quoteOption.deviationItinerary.isExpanded = true;
                        commonUtilities.scrollToItinerary("itinerary_" + itinerary.itineraryID);
                    }
                }
            }
        });

        this.setState({ quote });
    };

    onShowEmail = () => {
        let { isShowEmail } = this.state;
        // let { emails } = this.state;
        let { quote } = this.props;
        let params = {
            quoteNumber: quote?.data?.quoteNumber
        };

        isShowEmail = true;
        this.setState({ isShowEmail });
    };

    handleSendEmail = () => {
        let { isShowEmail, isEmailSend } = this.state;
        let { quote } = this.props;
        let { emails } = this.state;
        let params = {
            quoteNumber: quote?.data?.quoteNumber,
            emails: []
        };
        if (emails?.length > 0) {
            emails.map((eachEmail) => {
                params.emails.push(eachEmail.email);
            });
        }

        document.body.classList.remove("popup-open");
        isEmailSend = true;
        isShowEmail = false;
        this.setState({ isEmailSend, isShowEmail });

        setTimeout(() => {
            isEmailSend = false;
            this.setState({ isShowEmail, isEmailSend });
        }, 5000);

        this.props.dispatch(emailService.sendEmail(params)).then(
            (response) => {
                emails = [];
                document.body.classList.remove("popup-open");
                isShowEmail = false;
                this.setState({ isShowEmail, emails });
            },
            () => {
                document.body.classList.remove("popup-open");
                isShowEmail = false;
                this.setState({ isShowEmail });
            }
        );
    };

    onSendEmail = () => {
        let { quote } = this.props;
        let { isEmailSend } = this.state;
        let params = {
            quoteNumber: quote?.data?.quoteNumber,
            emails: [quote?.data?.contact?.emailAddress?.email]
        };
        isEmailSend = true;
        this.setState({ isEmailSend });

        setTimeout(() => {
            isEmailSend = false;
            this.setState({ isEmailSend });
        }, 5000);

        this.props.dispatch(emailService.sendEmail(params)).then((response) => {});
    };

    closeEmailPopup = () => {
        let { isEmailSend } = this.state;
        isEmailSend = false;
        this.setState({ isEmailSend });
    };

    handleEmailCancel = () => {
        let { isShowEmail, emailListUpdated } = this.state;

        document.body.classList.remove("popup-open");

        isShowEmail = false;
        emailListUpdated = false;
        this.setState({ isShowEmail, emailListUpdated });
    };

    handleEmailAdd = (email) => {
        if (email && email?.length > 0) {
            let { emails } = this.state;
            let emailAddress = { index: emails.length + 1, email: email };
            emails.push(emailAddress);
            this.setState({ emails });
        }
    };

    handleEmailDelete = (emailAddress) => {
        let { emails } = this.state;

        for (var i = 0; i < emails.length; i++) {
            if (emails[i].index === emailAddress.index) {
                emails.splice(i, 1);
            }
        }

        this.setState({ emails });
    };

    scrollToSection = (id) => {
        commonUtilities.scrollToSection(id);
    };

    showOrHideNotes = (isShow) => {
        window.scrollTo(0, 0);
        document.body.style.overflow = !isShow ? "visible" : "hidden";

        let { isPricingNotes } = this.state;
        isPricingNotes = isShow;

        this.setState({ isPricingNotes });
    };

    showOrHideTermsAndConditions = (isShow) => {
        window.scrollTo(0, 0);
        document.body.style.overflow = !isShow ? "visible" : "hidden";

        let { isTermsAndConditions } = this.state;
        isTermsAndConditions = isShow;

        this.setState({ isTermsAndConditions });
    };

    showOrHidePromotions = (flag) => {
        window.scrollTo(0, 0);
        document.body.style.overflow = !flag ? "visible" : "hidden";

        let { isPromotion } = this.state;
        isPromotion = flag;
        this.setState({ isPromotion });
    };

    onEditTerms = (id) => {

        let { termsAndConditions } = this.state;

        termsAndConditions[id].isShowTerms = !termsAndConditions[id].isShowTerms;
        termsAndConditions[id].isEditTerms = !termsAndConditions[id].isEditTerms;

        this.setState({
            termsAndConditions
        });
    };

    onCancelTerms = (id) => {

        let { termsAndConditions } = this.state;
        termsAndConditions[id].isShowTerms = true;
        termsAndConditions[id].isEditTerms = false;

        this.setState({
            termsAndConditions
        });
    };

    onChangeTerms = (event, row, value) => {

        let { termsAndConditions } = this.state;

        termsAndConditions[row][value] = event;
        this.setState({ termsAndConditions });

    };

    onChangePrice = (event, row, value) => {
        let { data } = this.state;
        data["pricingNotes"][row][value] = event;
        this.setState({ data });
    };

    onSaveTerms(id) {
        let { termsAndConditions } = this.state;
        let payload = {
            promotionInformation: null,
            pricingNotesInformation: null,
            termsAndConditions: termsAndConditions,
            groupQuoteLetter: 3
        };
        this.props.dispatch(updateQuoteLetter(payload)).then((response) => {
            // this.props.dispatch(getGroupContract());
            this.onCancelTerms(id);
            if (this.props.quote.data) {
                let { quoteNumber } = this.props.quote.data;
                this.props.dispatch(quote.get(quoteNumber));
            }
        });
    }

    onSavePromotions() {
        let { data, isShowPromotion, isEditPromotion } = this.state;
        let promo = [];

        data.promotions?.map((eachPromotion) => {
            if (eachPromotion?.isRemoved) {
                delete eachPromotion?.isRemoved;
                promo.push(eachPromotion);
            } else {
                delete eachPromotion?.isRemoved;
            }
        });

        this.setState({ data });

        let payload = {
            promotionInformation: promo,
            pricingNotesInformation: null,
            termsAndConditions: null,
            groupQuoteLetter: 1
        };
        isShowPromotion = true;
        isEditPromotion = false;
        this.props.dispatch(updateQuoteLetter(payload)).then(
            (response) => {
                if (this.props.quote.data) {
                    let { quoteNumber } = this.props.quote.data;
                    this.props.dispatch(quote.get(quoteNumber));
                }
                this.setState({ isShowPromotion, isEditPromotion });
            },
            () => {
                if (this.props.quote.data) {
                    let { quoteNumber } = this.props.quote.data;
                    this.props.dispatch(quote.get(quoteNumber));
                }
                this.setState({ isShowPromotion, isEditPromotion });
            }
        );
    }

    onPricingSave() {
        let { data, isShowPricing, isEditPricing } = this.state;
        let payload = {
            promotionInformation: null,
            pricingNotesInformation: data?.pricingNotes, 
            termsAndConditions: null,
            groupQuoteLetter: 2
        };

        isShowPricing = true;
        isEditPricing = false;

        this.props.dispatch(updateQuoteLetter(payload)).then((response) => {
            if (this.props.quote.data) {
                let { quoteNumber } = this.props.quote.data;
                this.props.dispatch(quote.get(quoteNumber));
            }
            this.setState({ isShowPricing, isEditPricing });
        },  () => {
            if (this.props.quote.data) {
                let { quoteNumber } = this.props.quote.data;
                this.props.dispatch(quote.get(quoteNumber));
            }
            this.setState({ isShowPricing, isEditPricing });
        });
    }

    handleClosePromotions = (value, index) => {
        let { data } = this.state;

        data.promotions[index].isRemoved = value;
        this.setState({ data });
    };

    handleClosePricing = (value, index) => {
        let { data } = this.state;

        data.pricingNotes[index].isRemoved = value;
        this.setState({ data });
    };

    onEditPromotion = (event) => {
        let { isShowPromotion, isEditPromotion, data } = this.state;
        if (data?.promotions && data?.promotions?.length > 0) {
            data?.promotions?.map((promotion) => {
                promotion["isRemoved"] = false;
            });
        }

        isShowPromotion = false;
        isEditPromotion = true;
        this.setState({
            isShowPromotion,
            isEditPromotion,
            data
        });
    };

    onCancelPromotion = () => {
        let { isShowPromotion, isEditPromotion } = this.state;
        isShowPromotion = true;
        isEditPromotion = false;
        this.setState({ isShowPromotion, isEditPromotion });
    };

    onEditPricing = (event) => {
        let { isShowPricing, isEditPricing, data } = this.state;
        if (data?.pricingNotes && data?.pricingNotes?.length > 0) {
            data?.pricingNotes?.map((pricing) => {
                pricing["isRemoved"] = false;
            });
        }

        isShowPricing = false;
        isEditPricing = true;
        this.setState({
            isShowPricing,
            isEditPricing,
            data
        });
    };

    onCancelPricing = () => {
        let { isShowPricing, isEditPricing } = this.state;
        isShowPricing = true;
        isEditPricing = false;
        this.setState({ isShowPricing, isEditPricing });
    };

    onClose = () => {
        const { quote } = this.props;
        if(this.props.navigate) {
            this.props.navigate(
                "/lead/" +
                quote?.data?.sfdcLeadNumber +
                "/quote/" +
                quote?.data?.quoteNumber
            );
        }
    };

    closeAlert = () => {
        let isAlertOn = this.state;
        isAlertOn = false;
        this.setState({ isAlertOn });
    };

    render() {
        let { quote, profile, sfdc, email } = this.props;
        const forPDF = this.props.forPDF ?? false;


        let {
            isShowEmail,
            emails,
            isPricingNotes,
            isTermsAndConditions,
            isPromotion,
            isShowTerms,
            isEditTerms,
            isEditPricing,
            isEditPromotion,
            isShowPricing,
            isShowPromotion,
            data,
            isEmailSend,
            isAlertOn,
            termsAndConditions
        } = this.state;   

        // if forPDF = true, isIQMUser should be passed in via props
        let isIQMUser = this.props.isIQMUser;
        if(isIQMUser === undefined) {
            isIQMUser = commonUtilities.isIQMUser();
        }

        return (
            <div className="row view-quote">
                <div className="quote-information-row">
                    <div className={"row box-sizing" + forPDF ? "" : " sticky"}>
                        <Section>
                            <div className="quote-header row">
                                <div className="pull-left">
                                    <div className="row margin-top-1-6">
                                        <Icon name="my-bookings" className="icon-24 quote-icon pull-left" />
                                        {isIQMUser ? (
                                            <div className="pull-left">
                                                {this.localize("qma-quote-number") + " "}
                                                {quote?.data?.quoteNumber}
                                                {".pdf"}
                                            </div>
                                        ) : (
                                            <div className="pull-left">
                                                {this.localize("qma-quote-number") + " "}
                                                {quote?.data?.quoteNumber}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {forPDF ? null : 
                                    <div className="print-hide pull-right">
                                        {isIQMUser ? (
                                            <div>
                                                {" "}
                                                <Button
                                                    data-testid={"cancel-email-contract"}
                                                    variant="outlined"
                                                    className="btn-red margin-right-1x"
                                                    onClick={() => this.onClose()}
                                                >
                                                    <strong>{this.localize("qma-cancel-quote-letter")}</strong>
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    className="btn-white"
                                                    data-testid={"emailBtn"}
                                                    onClick={() => this.onSendEmail()}
                                                >
                                                    <Icon
                                                        name="email"
                                                        className="pull-left icon-12 margin-right"
                                                    />
                                                    {this.localize("qma-email")}
                                                </Button>
                                            </div>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                className="btn-white"
                                                data-testid={"emailBtn"}
                                                onClick={() => this.onShowEmail()}
                                            >
                                                <Icon
                                                    name="email"
                                                    className="pull-left icon-12 margin-right"
                                                />
                                                {this.localize("qma-email")}
                                            </Button>
                                        )}
                                    </div>
                                }
                            </div>
                        </Section>

                        {isEmailSend && !forPDF ? (
                            <div>
                                <div className="popup-container-bg delete-modal"></div>

                                <div className="popup-container">
                                    <div className="popup-body">
                                        <div
                                            className="close-popup-button"
                                            onClick={() => this.closeEmailPopup()}
                                        >
                                            <span className="swg-icon swg-icon-close--n icon"></span>
                                        </div>

                                        <div className="popup-heading margin-bottom-1x email-popup-container">
                                            <div className="email-logo-cover"></div>
                                            <div className="email-declaration-text">
                                                {this.localize("qma-email-request")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {isShowEmail && !forPDF ? (
                            <Email
                                isShowEmail={isShowEmail}
                                emails={emails}
                                handleEmailAdd={(email) => this.handleEmailAdd(email)}
                                handleEmailDelete={(emailAddress) =>
                                    this.handleEmailDelete(emailAddress)
                                }
                                handleSendEmail={() => this.handleSendEmail()}
                                handleEmailCancel={() => this.handleEmailCancel()}
                                email={email}
                            />
                        ) : null}
                    </div>

                    {isIQMUser || forPDF ? null : (
                        <Section className="print-hide">
                            <div className="is-desktop link-row margin-top-1-4 row grey-color-bg">
                                <div className="quote-details-buttons pull-right margin-top-1-4 ">
                                    {quote?.data?.termsAndConditions !== null ? (
                                        <Button
                                            className="show-pricing-notes link-button"
                                            onClick={() => this.showOrHideTermsAndConditions(true)}
                                        >
                                            {this.localize("qma-terms-condition")}
                                        </Button>
                                    ) : null}
                                </div>
                                <div className="quote-details-buttons pull-right margin-top-1-4 margin-right border-right">
                                    {quote?.data?.pricingNotes !== null ? (
                                        <Button
                                            className="link-button"
                                            onClick={() => this.showOrHideNotes(true)}
                                        >
                                            {this.localize("qma-pricing-notes")}
                                        </Button>
                                    ) : null}
                                </div>
                                <div className="quote-details-buttons pull-right margin-top-1-4 margin-right border-right">
                                    {quote?.data?.promotions !== null ? (
                                        <Button
                                            data-testid="show-all-btn"
                                            className="link-button"
                                            onClick={() => this.showOrHidePromotions(true)}
                                        >
                                            {this.localize("qma-promotions")}
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                            <div className="is-mobile link-row margin-top-1-4 row grey-color-bg">
                                <div className="pull-right margin-top-1-4 ">
                                    {quote?.data?.termsAndConditions !== null ? (
                                        <Button
                                            className="is-mobile link-button"
                                            data-testid={"termsAndConditionsBtn"}
                                            onClick={() => this.scrollToSection("termsAndConditions")}
                                        >
                                            {this.localize("qma-terms-condition")}
                                        </Button>
                                    ) : null}
                                </div>
                                <div className="pull-right margin-top-1-4 margin-right border-right">
                                    {quote?.data?.pricingNotes !== null ? (
                                        <Button
                                            className="link-button"
                                            data-testid={"pricingNotesBtn"}
                                            onClick={() => this.scrollToSection("pricingNotes")}
                                        >
                                            {this.localize("qma-pricing-notes")}
                                        </Button>
                                    ) : null}
                                </div>
                                <div className="pull-right margin-top-1-4 margin-right border-right">
                                    {quote?.data?.promotions !== null ? (
                                        <Button
                                            className="link-button"
                                            data-testid={"promotionsBtn"}
                                            onClick={() => this.scrollToSection("promotions")}
                                        >
                                            {this.localize("qma-promotions")}
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        </Section>
                    )}

                    <Section>
                        <div className="quote-information-container row margin-top-2x">
                            <div className="col-3">
                                <GroupDetails group={quote?.data?.groupInformation} languageCode={this.state.languageCode} />
                            </div>

                            <div className="col-3">
                                <ProfileInformation group={quote?.data?.groupInformation} profile={profile} sfdc={sfdc} forPDF={forPDF} isIQMUser={isIQMUser} languageCode={this.state.languageCode} />
                            </div>

                            <div className="col-3-no-margin-r">
                                {quote?.data?.salesRepresentative && (
                                    <RepresentativeInformation
                                        salesRepresentative={quote?.data?.salesRepresentative} forPDF={forPDF} languageCode={this.state.languageCode}
                                    />
                                )}
                            </div>
                        </div>
                    </Section>

                    {forPDF ? null : 
                        <Section>
                            <div className="product-types-container">
                                <ProductType
                                    quote={quote?.data}
                                    handleItinerary={(event, data, isItinerary) =>
                                        this.handleItinerary(event, data, isItinerary)
                                    }
                                />
                            </div>
                        </Section>
                    }
                    {isIQMUser || forPDF ? null : (
                        <Section className="print-hide">
                            <div className="row margin-top-3x margin-reset">
                                {quote?.data?.status?.toLowerCase() !== "confirmed" &&
                                  quote?.data?.isRequote === false &&
                                    (isIQMUser ? true : quote?.data?.quoteOptions?.length < 5) ? (
                                        <AddQuoteOption data={quote?.data} />
                                    ) : null}
                            </div>
                        </Section>
                    )}

                    <Section>
                        <ProductTypeDetails
                            quote={quote}
                            handleItinerary={(event, data, isItinerary) =>
                                this.handleItinerary(event, data, isItinerary)
                            }
                            forPDF={forPDF}
                            languageCode={this.state.languageCode}
                        />
                    </Section>

                    <Section>
                        <div className="row">
                            {quote?.data?.additionalRequirements && (
                                <div className="row margin-top-1x">
                                    <div className="row-additional-information">
                                        <h3 className="margin-bottom-1x">
                                            {this.localize("qma-additional-requirement")}
                                        </h3>
                                        {ReactHtmlParser(quote?.data?.additionalRequirements)}
                                    </div>
                                </div>
                            )}

                            {
                                <div className="row margin-top-2x background-blue font-white">
                                    <div className="row-additional-information font-20 generic-contract">
                                        {ReactHtmlParser(quote?.data?.contactMessageDisplayText)}
                                    </div>
                                </div>
                            }
                        </div>
                    </Section>
                </div>

                {!isIQMUser || forPDF ? (
                    <div id="promotions" className="promotion-row background-grey avoid-break-inside">
                        <Section className="background-grey">
                            {quote?.data?.promotions && (
                                <Promotions promotions={quote?.data?.promotions} forPDF={forPDF} languageCode={this.state.languageCode} />
                            )}
                            {forPDF ? null :
                                <div className={isPromotion ? "overlay show" : "hide"}>
                                    <div className="close-btn-row">
                                        <Button
                                            data-testid="close-btn"
                                            variant="default"
                                            onClick={() => this.showOrHidePromotions(false)}
                                        >
                                            <Icon name="close--n" className="icon font-grey" />
                                        </Button>
                                    </div>

                                    <div className="banner background-dark font-white">
                                        <div className="banner-img"></div>
                                        <h1 className="font-white">
                                            {this.localize("qma-current-promotions")}
                                        </h1>
                                    </div>
                                    <div className="promotions-row-scroll">
                                        <Promotion promotions={quote?.data?.promotions} forPDF={forPDF} />
                                    </div>
                                </div>
                            }
                        </Section>
                    </div>
                ) : (
                    <div>
                        {quote?.data?.promotions !== null ? (
                            <div id="promotions" className="promotion-row background-grey avoid-break-inside">
                                <Section>
                                    <div className="quote-header row margin-top-1x">
                                        <div className="pull-left margin-top-1-6">
                                            <Icon name="promotion" /> &nbsp;
                                            <b>Promotions</b>
                                        </div>
                                        <div className="pull-right">
                                            {isShowPromotion ? (
                                                <Button
                                                    data-testid={"edit-btn"}
                                                    variant="outlined"
                                                    className="btn-white"
                                                    onClick={() => this.onEditPromotion()}
                                                >
                                                    <Icon name="edit--n" className="margin-right" />
                                                    {this.localize("qma-edit")}
                                                </Button>
                                            ) : null}

                                            {!isShowPromotion ? (
                                                <div className="row">
                                                    <Button
                                                        data-testid={"cancel-btn"}
                                                        variant="outlined"
                                                        className="btn-white margin-right-1x cancel-button"
                                                        onClick={this.onCancelPromotion}
                                                    >
                                                        {this.localize("qma-cancel")}
                                                    </Button>
                                                    {}
                                                    <Button
                                                        data-testid={"save-btn"}
                                                        variant="outlined"
                                                        className="btn-white"
                                                        onClick={() => this.onSavePromotions()}
                                                    >
                                                        <Icon
                                                            name={"checked--n"}
                                                            className="margin-right"
                                                        />
                                                        {this.localize("qma-save")}
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                      
                                    </div>
                                    <div className="row margin-top-4x">
                                        {" "}
                                        {quote?.data?.promotions && (
                                            <Promotions
                                                promotions={data?.promotions}
                                                isEdit={!isShowPromotion}
                                                handleClose={(value, index) =>
                                                    this.handleClosePromotions(value, index)
                                                }
                                                forPDF={forPDF} 
                                                languageCode={this.state.languageCode}
                                            />
                                        )}
                                    </div>
                                </Section>
                            </div>
                        ) : null}
                    </div>
                )}

                {quote?.data?.pricingNotes && (
                    <div id="pricingNotes">
                        <Section>
                            {forPDF ? null : 
                                <div className={isPricingNotes ? "overlay show" : "hide"}>
                                    <div className="close-btn-row">
                                        <Button
                                            data-testid="close-btn"
                                            variant="default"
                                            onClick={() => this.showOrHideNotes(false)}
                                        >
                                            <Icon name="close--n" className="icon font-grey" />
                                        </Button>
                                    </div>
                                    <div>
                                        <ul className="pricing-notes-information-row margin-top-1x">
                                            {quote?.data?.pricingNotes.map((notes, index) => (
                                                <li key={index}>{notes.description}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            }
                            {forPDF ? null : 
                                <div className="show-pricing-notes margin-top-1x">
                                    {quote?.data?.pricingNotes !== null ? (
                                        <Button
                                            variant="outlined"
                                            onClick={() => this.showOrHideNotes(true)}
                                        >
                                            {this.localize("qma-pricing-notes")}
                                        </Button>
                                    ) : null}
                                </div>
                            }
                            {!isIQMUser || forPDF ? (
                                <div className="pricing-notes-text">
                                    <div className="quote-header row margin-top-4x">
                                        <div className="margin-top-1-4">
                                            <Icon name="info--o" /> &nbsp;
                                            <b>{this.localize("qma-pricing-notes")}</b>
                                        </div>
                                    </div>
                                    <ul className="pricing-notes-information-row margin-top-1x">
                                        {quote?.data?.pricingNotes.map((notes, index) => (
                                            <li key={index}>{notes.description}</li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div className="schedule-of-cancellation-container">
                                    <div className="quote-header row margin-top-2x row">
                                        <div className="pull-left margin-top-1-4">
                                            <Icon name="info--o" /> &nbsp;
                                            <b>Important information and pricing</b>
                                        </div>
                                        <div className="pull-right">
                                            {isShowPricing ? (
                                                <Button
                                                    data-testid={"edit-btn"}
                                                    variant="outlined"
                                                    className="btn-white"
                                                    onClick={() => this.onEditPricing()}
                                                >
                                                    <Icon name="edit--n" className="margin-right" />
                                                    {this.localize("qma-edit")}
                                                </Button>
                                            ) : null}

                                            {!isShowPricing ? (
                                                <div className="row">
                                                    <Button
                                                        data-testid={"cancel-btn"}
                                                        variant="outlined"
                                                        className="btn-white margin-right-1x cancel-button"
                                                        onClick={this.onCancelPricing}
                                                    >
                                                        {this.localize("qma-cancel")}
                                                    </Button>
                                                    {}
                                                    <Button
                                                        data-testid={"save-btn"}
                                                        variant="outlined"
                                                        className="btn-white"
                                                        onClick={() => this.onPricingSave()}
                                                    >
                                                        <Icon
                                                            name={"checked--n"}
                                                            className="margin-right"
                                                        />
                                                        {this.localize("qma-save")}
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            <div className="pricing-notes-text">
                                                <ul className="pricing-notes-information-row margin-top-1x">
                                                    {quote?.data?.pricingNotes.map(
                                                        (notes, pricingIndex) => (
                                                            <li key={pricingIndex}>
                                                                {" "}
                                                                {!isEditPricing ? (
                                                                    notes.description
                                                                ) : (
                                                                    <div className="row">
                                                                        <textarea className="terms-condition-container"
                                                                            fullWidth={true}
                                                                            rows={5}
                                                                            id={pricingIndex}
                                                                            value={notes.description}
                                                                            onChange={(event) => this.onChangePrice(event?.target?.value, pricingIndex, "description")}
                                                                        />
                                                                        
                                                                        <div className="margin-top-1x cross-button-pricing">{notes?.isRemoved ?
                                                                            <div onClick={() => this.handleClosePricing(false, pricingIndex)}><Icon name="refresh--o"
                                                                                className="icon-24 icon-flipped margin-left-1x margin-top close-icon" /></div>
                                                                            : <div onClick={() => this.handleClosePricing(true, pricingIndex)}><Icon name="close--o"
                                                                                className="icon-24 margin-left-1x margin-top close-icon" /></div>
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </Section>
                    </div>
                )}

                {quote?.data?.termsAndConditions && (
                    <div
                        id="termsAndConditions"
                        className="terms-and-conditions-information-row"
                    >
                        <Section>
                            {forPDF ? null :
                                <div className={isTermsAndConditions ? "overlay show" : "hide"}>
                                    <div className="close-btn-row">
                                        <Button
                                            data-testid="close-btn"
                                            variant="default"
                                            onClick={() => this.showOrHideTermsAndConditions(false)}
                                        >
                                            <Icon name="close--n" className="icon font-grey" />
                                        </Button>
                                    </div>

                                    <div>
                                        {quote?.data?.termsAndConditions?.map((term, index) => (
                                            <div key={index}>
                                                <div className="quote-header row margin-top-4x">
                                                    <div className="pull-left margin-top-1-4">
                                                        {!forPDF ? null : <><Icon name="info--o" />&nbsp;</>}
                                                        <b>{ReactHtmlParser(term.title)}</b>
                                                    </div>
                                                </div>
                                                {ReactHtmlParser(term.description)}
                                            </div>
                                        ))}

                                        {quote?.data?.noticeOfConfidentialityDisplayText &&
                                            <div className="border-top notice-Of-confidentiality-display-text">
                                                <div className="margin-top-2x">
                                                    {quote?.data?.noticeOfConfidentialityDisplayText}
                                                </div>
                                            </div>  
                                        }
                                    </div>
                                </div>
                            }
                          
                            {forPDF ? null :
                                <div className="show-pricing-notes margin-top-1x">
                                    {quote?.data?.termsAndConditions !== null ? (
                                        <Button
                                            variant="outlined"
                                            onClick={() => this.showOrHideTermsAndConditions(true)}
                                        >
                                            {this.localize("qma-terms-condition")}
                                        </Button>
                                    ) : null}
                                </div>
                            }
                            {!isIQMUser || forPDF ? (
                                <div>
                                    <div className="terms-and-conditions-text">
                    {quote?.data?.termsAndConditions?.map((term, index) => (
                      <div key={index}>
                        <div className="quote-header margin-top-4x">
                                                    <div className="pull-left margin-top-1-4">
                                                        {!forPDF ? null : <><Icon name="info--o" />&nbsp;</>}
                                                        <b>{ReactHtmlParser(term.title)}</b>
                                                    </div>
                                                </div>
                                                {ReactHtmlParser(term.description)}
                      </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="schedule-of-cancellation-container">
                                    <div>
                                        {termsAndConditions?.map((term, index) => (
                                            !term.isEditTerms ? (
                              <div key={index}>
                                                    <div className="quote-header row margin-top-4x">
                                                        <div className="pull-left margin-top-1-4">
                                                            <Icon name="info--o" /> &nbsp;
                                                            <b>{ReactHtmlParser(term.title)}</b>
                                                        </div>
                                                        <div className="pull-right">
                                                            {term.isShowTerms ? (
                                                                <Button
                                                                    data-testid={"edit-btn"}
                                                                    variant="outlined"
                                                                    className="btn-white"
                                                                    onClick={() => this.onEditTerms(index)}
                                                                >
                                                                    <Icon name="edit--n" className="margin-right" />
                                                                    {this.localize("qma-edit")}
                                                                </Button>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    {ReactHtmlParser(term.description)}
                              </div>
                                            ) : (
                              <div key={index}>
                                                    <div className="quote-header row margin-top-4x">
                                                        <div className="pull-left margin-top-1-4">
                                                            <Icon name="info--o" /> &nbsp;
                                                            <b>{ReactHtmlParser(term.title)}</b>
                                                        </div>
                                                        {!term.isShowTerms ? (
                                                            <div className="pull-right">
                                                                <Button
                                                                    data-testid={"cancel-btn"}
                                                                    variant="outlined"
                                                                    className="btn-white margin-right-1x cancel-button"
                                                                    id={"cancel_"+term.id}
                                                                    onClick={() => this.onCancelTerms(index)}
                                                                >
                                                                    {this.localize("qma-cancel")}
                                                                </Button>
                                                                {}
                                                                <Button
                                                                    data-testid={"save-btn"}
                                                                    variant="outlined"
                                                                    className="btn-white"
                                                                    id={"save_"+term.id}
                                                                    onClick={() => this.onSaveTerms(index)}
                                                                >
                                                                    <Icon
                                                                        name={"checked--n"}
                                                                        className="margin-right"
                                                                    />
                                                                    {this.localize("qma-save")}
                                                                </Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <textarea
                                                        className="terms-condition-container"
                                                        fullWidth={true}
                                                        rows={5}
                                                        id={index}
                                                        value={term?.description}
                                                        onChange={(event) =>
                                                            this.onChangeTerms(
                                                                event?.target?.value,
                                                                index,
                                                                "description"
                                                            )
                                                        }
                                                    >
                                                    </textarea>
                              </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Section>
                    </div>
                )}

                {quote?.data?.noticeOfConfidentialityDisplayText && (
                    <Section>
                        <div className="border-top notice-Of-confidentiality-display-text">
                            <div className="margin-top-2x">
                                {quote?.data?.noticeOfConfidentialityDisplayText &&
                                quote?.data?.noticeOfConfidentialityDisplayText}
                            </div>
                        </div>
                    </Section>
                )}

                {isIQMUser && !forPDF ? (
                    <div className="margin-top-6x">
                        <div className="text-center">
                            <Button
                                data-testid={"cancel-email-contract"}
                                variant="outlined"
                                className="btn-red margin-right-1x"
                                onClick={() => this.onClose()}
                            >
                                <strong>Cancel quote letter</strong>
                            </Button>

                            <Button
                                data-testid={"save-email-group-contract"}
                                variant="outlined"
                                className="btn-blue"
                                onClick={() => this.onSendEmail()}
                            >
                                <Icon name={"email"} className="margin-right" />
                                <strong>{this.localize("qma-email")}</strong>
                            </Button>
                        </div>
                    </div>
                ) : null}
                {quote?.data === undefined && !forPDF ? (
                    <div className="lds-container">
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                ) : null}

                {quote?.data === null && isAlertOn && !forPDF ? (
                    <div>
                        <div className="popup-container-bg delete-modal"></div>

                        <div className="popup-container">
                            <div className="popup-body">
                                <div
                                    className="close-popup-button"
                                    onClick={() => this.closeAlert()}
                                >
                                    <span className="swg-icon swg-icon-close--n icon"></span>
                                </div>

                                <div className="popup-heading margin-bottom-1x email-popup-container">
                                    <Icon
                                        name="alert--o"
                                        className="icon-12 warning-alert-prompt"
                                    />
                                    <div className="email-declaration-text">
                                        {this.localize("qma-quote-not-found")}
                                    </div>
                                    <Button
                                        className="go-back-btn"
                                        variant="contained"
                                        onClick={() => this.props.navigate("/review")}
                                    >
                                        {this.localize("qma-go-back")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    email: state.emails
});

export default connect(mapStateToProps)((View));
