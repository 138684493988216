import { client } from "api/client";
import email from "components/common/email/email";
import * as actions from "modules/common/store/actions";
import * as utility from "utilities/utils";

function sendEmail(params) {
    return async (dispatch) => { 
    
        return await client.post(process.env.REACT_APP_EMAIL, params).then((response) => {
      
            return dispatch({
                type: actions.SEND_EMAIL_SUCCESS,
                payload: utility.getResponse(response)
            });
        })
            .catch((error) => {
                return dispatch({
                    type: actions.SEND_EMAIL_FAIL,
                    payload: utility.getErrorResponse(error)
                });
            });
    };
}


const emailService = {
    sendEmail
};

export default emailService;

