import React, { Component } from "react";
import { connect } from "react-redux";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { GetLanguageCode } from "utilities/localize";
import commonUtilities from "utilities/common";
import getGroupProductTypes from "services/productType/productType.service";
import * as actions from "modules/common/store/actions";

class ProductTypeOption extends Component {

    componentDidMount() {
        const { productType, deviationType, getDestinations, dispatch } = this.props;
        dispatch(getGroupProductTypes(GetLanguageCode()));
        if (productType?.productType && productType?.productType?.name?.toLowerCase() !== "deviation") {
            getDestinations(productType?.productType);
        }
    }

    handleData = (event) => {
        let { productType, handleStep2Data, getDestinations, productTypes, resetStep2, enabledDates } = this.props;
        
        let previousIsDeviation = productType.productType?.name?.toLowerCase() === "deviation";

        productType.productType.code = event.target.value;
        productType.productType.name = productTypes?.data?.filter(product => product.code === productType?.productType?.code)?.[0]?.name;
        if (enabledDates?.data?.length > 0 && productType.productType?.name?.toLowerCase() !== "deviation") {
            this.props.dispatch({
                type: actions.GET_FLIGHTS_DATES_SUCCESS,
                payload: { data: [] }
            });
        }

        resetStep2(previousIsDeviation);
        handleStep2Data(productType);
        if (productType.productType && productType.productType?.name?.toLowerCase() !== "deviation") {
            getDestinations(productType.productType);
        }
    };

    handleChange = (event) => {
        let { productType, handleStep2Data, resetStep2 } = this.props;
        productType.isManualQuote = event.target.checked;
        handleStep2Data(productType);
        if (!productType.isManualQuote) {
            resetStep2(null);
        }
    };

    render() {
        let isIQMUser = commonUtilities.isIQMUser();

        const { productTypes, productType, isManualQuote, isClone, isEdit } = this.props;


        let defaultValue = productTypes?.data?.[0]?.code || "Package";
        if(isClone && isEdit) {
            defaultValue = productType?.productType?.code || "";
        }

        return (
            <div className="row">
                <div className="row">
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="code"
                        data-testid="radio-group-product-option"
                        onChange={this.handleData}
                        defaultValue={defaultValue}
                    >
                        {!(isClone && isEdit) && productTypes?.data?.map((product, index) => (
                            <FormControlLabel
                                value={product?.code}
                                control={<Radio />}
                                label={product?.name}
                                key={index}
                                disabled={isClone}
                            />
                        ))}

                        {(isClone && isEdit) ?
                            <FormControlLabel
                                value={productType?.productType?.code}
                                control={<Radio />}
                                label={productType?.productType?.name} /> : null}

                    </RadioGroup>
                </div>
                {productType?.productType?.code !== "Deviation" ?
                    <div className={isIQMUser ? "show row margin-top-1x" : "hide"} data-testid="quote-manual">
                        <FormControlLabel
                            control={<Checkbox onChange={this.handleChange} checked={isManualQuote} />}
                            label={!isManualQuote ? "Quote manually for this option." : "Quote manually for this option.Please note: Changing it to not-manual, will lead to re-start the process, for this option."}
                        />
                    </div>
                    : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    productTypes: state.productTypes,
    enabledDates: state.flightsDates
});

export default connect(mapStateToProps)(ProductTypeOption);
