import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";

import Routes from "./routes";

import { oktaAuthConfig } from "modules/okta/oktaConfig";

const authorizationConfig = new OktaAuth(oktaAuthConfig);

const App = () => {

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        if(typeof window !== "undefined") {
            window.location.replace(
                toRelativeUrl(originalUri || "/", window.location.origin + "/")
            );
        }
    };

    return (
        <div className="row">
            <ScrollToTop smooth color="#FF6000" />
            <div className="container responsive">
                <Security oktaAuth={authorizationConfig} restoreOriginalUri={restoreOriginalUri}>
                    <Routes />
                </Security>
            </div>
        </div>
    );
};

export default App;