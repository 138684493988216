import React, { Fragment } from "react";
import moment from "moment";

import { Localize } from "utilities/localize";
import { CONFIGURATION } from "utilities/configuration";
import ProductTypeIcon from "../common/productTypeIcon/productTypeIcon";

const DeviationType = (props) => {

    const { productType } = props;

    // console.log("dev type", productType);

    const andLabel = Localize("qma-and");
    const childrenLabel = Localize(productType?.noOfChildren > 1 ? "qma-children" : "qma-child");
    const adultLabel = Localize(productType?.noOfAdults > 1 ? "qma-adults" : "qma-adult");

    return (
        <Fragment>
            {productType ? (
                <div className="row" data-testid="prod-comp">                    
                    <div className="row" >
                        Destinations
                    </div>
                    <div className="row" >

                        {
                            productType?.deviation?.map(e => e.destination).map(e => e.providerCode ? ` (${e.providerCode}) ${e.name}` : null)?.filter(e => e).join(",")
                        }

                    </div>
                    <div className="row margin-top-1x" >
                        Departure City
                    </div>
                    <div className="row" >

                        {
                            Array?.isArray(productType?.departures) && productType?.departures?.map(e => ` (${e.code}) ${e.name}`).join(",")
                        }
                    </div>
                    <div className="row">
                        {
                            Localize("qma-on") + " " + moment(productType.departureDate).format(CONFIGURATION.DATE_FORMAT) +
                            " - " + moment(productType.returnDate).format(CONFIGURATION.DATE_FORMAT) 
                        }
                        {productType?.noOfAdults !== "" ? " with " + productType?.noOfAdults + " Adults" : ""}
                        {productType?.noOfChildren !== "" ? " and " + productType?.noOfChildren + " Children" : ""}
                    </div>


                    <div className="row flex-wrap">
                        {productType?.deviation?.map((e) => (
                            <>
                                {e.products.map((product, index) => product ? (
                                    <div key={index} className={product.name.trim().length ? "show col-3 margin-top-1x" : "hide"}>
                                        <div className="row text-ellipsis">

                                            {
                                                product.name.trim().length > 0
                                                    ? <ProductTypeIcon productType="hotel" className="font-orange"/>
                                                    : ""
                                            }

                                            {product.name}
                                        </div>

                                        {product.roomTypes?.map((roomType, roomTypeIndex) => (
                                            <div key={roomTypeIndex} className="row text-ellipsis">
                                                {roomType.code === CONFIGURATION.CUSTOM_ROOM_TYPE_CODE && roomType.customName !== undefined && roomType.customName !== "" ? roomType.customName : roomType.name}
                                            </div>
                                        ))}
                                    </div>
                                ) : null
                                )}
                            </>
                        ))}

                    </div>

                </div>
            ) : null
            }
        </Fragment >
    );
};
export default DeviationType;