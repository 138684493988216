import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { Button, Switch } from "@mui/material";
import { Section } from "@sunwing/components";
import { Icon } from "__ICON_REFERENCE__";
import { Tooltip } from "@mui/material";

import updateItinerary from "services/itinerary/itinerary.service";
import { default as quoteService } from "services/quote/quote.service";

import { Localize } from "utilities/localize";
import commonUtilities from "utilities/common";
import { CONSTANTS } from "utilities/constants";
import { withRouter } from "utilities/withRouter";
import { CONFIGURATION } from "utilities/configuration";
import GroupDetails from "../../../common/groupDetails/groupDetails";
import AddQuoteOption from "../../../common/addQuoteOption/addQuoteOption";
import Confirmation from "../../../common/popups/confirmation/confirmation";
import ProfileInformation from "../../../common/profileInformation/profileInformation";
import RepresentativeInformation from "../../../common/representativeInformation/representativeInformation";
import QuoteModal from "../../../common/quoteModal";
import getCloneDetails from "services/clone/clone.service";

import "./index.css";

class ViewGroupQuote extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isShow: false,
            isButtonDisabled: false,
            selectedAction: "",
            selectedData: {},
            selectedItinerary: {},
            isClone: false,
            cloneData: {},
            cloneIndex: -1,
            cloneType: "product"
        };
    }

    handleChange = (quoteOption, itinerary, action) => {
        let { selectedData, selectedItinerary, selectedAction } = this.state;
        selectedItinerary = itinerary;
        selectedAction = action;

        selectedData = {
            option: quoteOption?.productType?.name,
            itineraryText: itinerary.heading,
            unavailablePopUpTitleText: quoteOption?.unavailablePopUpTitleText,
            unavailablePopUpText: quoteOption?.unavailablePopUpText,
            activeInactivePopUpTitleText: quoteOption?.activeInactivePopUpTitleText,
            activeInactiveText: quoteOption?.activeInactiveText
        };

        this.setState({ selectedData, selectedItinerary, selectedAction });
        this.showOrHideConfirmationPopup(true);
        this.disableConfirmationButtons(false);
    };

    setItineraryActive = () => {
        let { selectedItinerary } = this.state;

        selectedItinerary.isActive = !selectedItinerary.isActive;
        this.setState({ selectedItinerary });
    };

    setItineraryUnavailable = () => {
        let { selectedItinerary } = this.state;

        selectedItinerary.status = CONSTANTS.UNAVAILABLE;
        selectedItinerary.isAvailable = !selectedItinerary.isAvailable;
        this.setState({ selectedItinerary });
    };

    onSubmit = (quoteData) => {
        this.disableConfirmationButtons(true);
        let { itinerary, quote } = quoteData;
        let { selectedAction, selectedItinerary } = this.state;

        switch (selectedAction.toLowerCase()) {
        case CONSTANTS.ACTIVE.toLowerCase():
            this.setItineraryActive();
            break;

        case CONSTANTS.AVAILABLE.toLowerCase():
            this.setItineraryUnavailable();
            break;
        }
        let { itineraryID, itineraryNumber, quoteOptionID, isActive, isAvailable } =
          selectedItinerary;
        let params = {
            itineraryID: itineraryID,
            itineraryNumber: itineraryNumber,
            quoteOptionID: quoteOptionID,
            isActive: isActive,
            isAvailable: isAvailable
        };

        this.props.dispatch(updateItinerary(params)).then((response) => {
            setTimeout(() => {
              
            }, 100000);
            this.showOrHideConfirmationPopup(false);
            this.disableConfirmationButtons(false);

            let { quoteNumber } = this.props.params;
            this.props.dispatch(quoteService.get(quoteNumber));
        });
    };

    onCancel = () => {
        this.showOrHideConfirmationPopup(false);
        this.disableConfirmationButtons(false);
    };

    showOrHideConfirmationPopup = (isDisplay) => {
        let { isShow } = this.state;
        isShow = isDisplay;

        this.setState({ isShow });
    };

    disableConfirmationButtons = (disabled) => {
        let { isButtonDisabled } = this.state;
        isButtonDisabled = disabled;

        this.setState({ isButtonDisabled });
    };

    onViewQuoteItinerary = (quoteNumber, itineraryID, code) => {
        const { leadNumber } = this.props.params;
        if (code !== "Deviation") {
            this.props.navigate(
                "/lead/" +
                leadNumber +
                "/quote/" +
                quoteNumber +
                "/itinerary/" +
                itineraryID
            );
        } else {
            this.props.navigate(
                "/lead/" +
                leadNumber +
                "/quote/" +
                quoteNumber +
                "/itinerary" +
                "/deviation/" +
                itineraryID
            );
        }
    };

    reverseCustomRoomName(productType) {
        let refinedProductType = productType;
        refinedProductType.products.forEach(product => {
            if(product.roomTypes) {
                product.roomTypes.forEach(roomType => {
                    if(roomType?.code === CONFIGURATION.CUSTOM_ROOM_TYPE_CODE) {
                        roomType.customName = roomType.name;
                        roomType.name = Localize("qma-custom-room-type");
                    }
                });
            }
        });
        return refinedProductType;
    }

    setClone = (quoteNumber, quoteOption) => {
        let { isClone, cloneIndex, cloneData, cloneType } = this.state;
        isClone = true;
        cloneIndex = 0;

        this.props
            .dispatch(getCloneDetails(quoteNumber, quoteOption?.optionID))
            .then((response) => {
                // console.log("response :::::::: ", response);
                if (quoteOption?.productType?.code?.toLowerCase() === "deviation") {
                    cloneData = response?.payload?.data?.data?.deviationTypes[0];
                    if(cloneData && cloneData.deviation) {
                        cloneData.deviation.forEach(deviationType => {
                            deviationType = this.reverseCustomRoomName(deviationType);
                        });
                    }
                    cloneType = "deviation";
                } else {
                    cloneData = this.reverseCustomRoomName(response?.payload?.data?.data?.productTypes[0]);
                    cloneType = "product";
                }

                this.setState({ isClone, cloneIndex, cloneData, cloneType });
            });
    };
    closeMOdal = (param) => {
        if (!param) {
            this.setState({ isClone: false, cloneData: null, cloneIndex: -1 });
        }
    };

    onGenerateQuote = () => {
        let { quote } = this.props;
        const { leadNumber } = this.props.params;
        this.props.navigate(
            "/lead/" + leadNumber + "/generateQuote/" + quote?.data?.quoteNumber
        );
    };

    render() {
        let { quote } = this.props;
        let {
            isShow,
            isButtonDisabled,
            selectedData,
            selectedItinerary,
            selectedAction,
            isClone,
            cloneData,
            cloneIndex,
            cloneType
        } = this.state;

        if (quote?.data) {
            let { quoteOptions, optionCreatedDate } = quote.data;
            const isIQMUser = commonUtilities.isIQMUser();
            return (
                <div className="row view-quote">
                    <div className="row box-sizing">
                        <Section className="sticky">
                            <div className="quote-header row">
                                <div className="pull-left margin-top-1-6 margin-right-1x">
                                    <Icon
                                        name="my-bookings"
                                        className="pull-left icon-24 margin-right"
                                    />
                                    <div className="pull-left">
                                        <div className="pull-left">
                                            {Localize("qma-quote-number") + " "}
                                            {quote?.data?.quoteNumber}
                                        </div>

                                        {quote?.data?.status?.toLowerCase() ===
                                            CONSTANTS.CONFIRMED.toLowerCase() ? (
                                                <div className="confirmed itinerary-status margin-header-confirmed pull-left">
                                                    {quote?.data?.status}
                                                </div>
                                            ) : null}
                                    </div>
                                </div>
                                <div className="pull-right print-hide">
                                    <Button
                                        variant="outlined"
                                        className="btn-white font-bold"
                                        data-testid={"generateQuoteBtn"}
                                        onClick={() => this.onGenerateQuote()}
                                    >
                                        <Icon
                                            name="email"
                                            className="icon-12 margin-right"
                                        />
                                        {Localize("qma-generate-quote-letter")}
                                    </Button>
                                </div>
                            </div>
                        </Section>

                        <Section>
                            <div className="quote-information-container row margin-top-2x">
                                <div className="col-3">
                                    <GroupDetails group={quote?.data?.groupInformation} />
                                </div>

                                <div className="col-3">
                                    <ProfileInformation />
                                </div>

                                <div className="col-3-no-margin-r">
                                    <RepresentativeInformation
                                        salesRepresentative={quote?.data?.salesRepresentative}
                                    />
                                </div>
                            </div>
                        </Section>

                        <Section>
                            <div className="view-quote-grid-container">
                                <div className="view-quote-grid-head row">
                                    <div className="view-quote-option">
                                        {Localize("qma-option")}
                                    </div>
                                    <div className="view-quote-created-on">
                                        {Localize("qma-created-on")}
                                    </div>
                                    <div className="view-quote-date-duration">
                                        {Localize("qma-departure-date")}
                                        <br />
                                        {Localize("qma-duration")}
                                    </div>
                                    <div className="view-quote-destination-departure">
                                        {Localize("qma-destination")}
                                        <br />
                                        {Localize("qma-departure-city")}
                                    </div>

                                    <div className="view-quote-product-itinerary">
                                        <div className="row">
                                            <div className="product-itinerary">
                                              Product <br />
                                              Itinerary
                                            </div>
                                            <div className="view-quote-available-unavailable">
                                                Available
                                                <br />
                                                Unavailable
                                            </div>
                                            <div className="view-quote-active-inactive switch-header">
                                                <div className="row">
                                                    <Switch
                                                        className="margin-right"
                                                        checked={true}
                                                        name="activeOrInactiveHeader"
                                                    />
                                                    Active
                                                </div>
                                                <div className="row">
                                                    <Switch
                                                        className="margin-right"
                                                        checked={false}
                                                        name="activeOrInactiveHeader"
                                                    />
                                                    Inactive
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="view-quote-clone-option">
                                        Clone
                                        <br />
                                        Option
                                    </div>
                                </div>

                                <div className="view-quote-grid-body">
                                    {quoteOptions.map((quoteOption, index) => (
                                        <div key={index} className="view-quote-grid-row row">
                                            <div className="view-quote-option">
                                                {index + 1 + ". " + quoteOption?.productType?.code}
                                            </div>
                                            <div className="view-quote-created-on">
                                                {Moment(quoteOption?.optionCreatedDate).format(
                                                    CONFIGURATION.DATE_FORMAT
                                                )}
                                            </div>
                                            {quoteOption?.productType?.code?.toLowerCase() ===
                                              "deviation" ? (
                                                    <div className="view-quote-date-duration">&nbsp;</div>
                                                ) : (
                                                    <div className="view-quote-date-duration">
                                                        <div>
                                                            {Moment(quoteOption?.departureDate).format(
                                                                CONFIGURATION.DATE_FORMAT
                                                            )}
                                                        </div>
                                                        <div>
                                                            {commonUtilities.calculateDuration(
                                                                quoteOption?.departureDate,
                                                                quoteOption?.returnDate
                                                            )} {Localize("qma-night")}
                                                        </div>
                                                    </div>
                                                )}
                                            <div className="view-quote-destination-departure">
                                                {quoteOption?.productType?.code?.toLowerCase() !==
                                                  "deviation" ? (
                                                        <div>
                                                            {"(" +
                                                            quoteOption?.destination?.providerCode +
                                                            ") " +
                                                            quoteOption?.destination?.name}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {quoteOption?.deviationItinerary?.deviationRequest?.map(
                                                                (deviation, index) => (
                                                                    <div key={index}>
                                                                        {"(" +
                                                                        deviation?.destination?.providerCode +
                                                                        ") " +
                                                                        deviation?.destination?.name}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}

                                                {quoteOption?.departure?.code !== "" &&
                                                quoteOption?.productType?.code?.toLowerCase() !==
                                                  "deviation" ? (
                                                        <div>
                                                            {"(" +
                                                            quoteOption?.departure?.code +
                                                            ") " +
                                                            quoteOption?.departure?.name}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {quoteOption?.departures?.map((departure, index) => (
                                                                <div key={index}>
                                                                    {"(" + departure?.code + ") " + departure?.name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                            </div>

                                            {quoteOption?.deviationItinerary === null ? (
                                                <div className="view-quote-product-itinerary">
                                                    {quoteOption.products.map((product, productIndex) => (
                                                        <div
                                                            key={productIndex}
                                                            className="row text-ellipsis margin-bottom-1x"
                                                        >
                                                            {product.name !== null &&
                                                              product.name.trim().length > 0 ? (
                                                                    <div className="product-name">
                                                                        {product?.name}
                                                                    </div>
                                                                ) : null}

                                                            {product.itineraries?.map(
                                                                (itinerary, itineraryIndex) => (
                                                                    <div key={itineraryIndex} className="row">
                                                                        <Tooltip
                                                                            title={itinerary?.heading}
                                                                            placement="top-start"
                                                                        >
                                                                            <div className="product-itinerary">
                                                                                <a
                                                                                    onClick={() =>
                                                                                        this.onViewQuoteItinerary(
                                                                                            quote?.data?.quoteNumber,
                                                                                            itinerary?.itineraryID,
                                                                                            quoteOption?.productType?.code
                                                                                        )
                                                                                    }
                                                                                    className="link-button"
                                                                                >
                                                                                    {itinerary.status !== "" ? (
                                                                                        <div className="row d-flex">
                                                                                            <div className="margin-right itinerary-number">
                                                                                                {itinerary?.itineraryNumber}
                                                                                            </div>
                                                                                            <div
                                                                                                className={
                                                                                                    itinerary?.status?.toLowerCase() +
                                                                                                  " itinerary-status margin-right"
                                                                                                }
                                                                                            >
                                                                                                {itinerary?.status}
                                                                                            </div>
                                                                                            <div>
                                                                                                <Icon
                                                                                                    name={
                                                                                                        quoteOption?.productType?.code
                                                                                                            .trim()
                                                                                                            .toLowerCase() ===
                                                                                                        "package"
                                                                                                            ? "flight"
                                                                                                            : quoteOption?.productType?.code
                                                                                                                .trim()
                                                                                                                .toLowerCase()
                                                                                                    }
                                                                                                    className={
                                                                                                        "icon-16 margin-right flight-icon"
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="itinerary-heading text-ellipsis">
                                                                                                {itinerary?.heading}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="row d-flex">
                                                                                            <div className="margin-right itinerary-number">
                                                                                                {itinerary?.itineraryNumber}
                                                                                            </div>
                                                                                            <div>
                                                                                                <Icon
                                                                                                    name={
                                                                                                        quoteOption?.productType?.code
                                                                                                            .trim()
                                                                                                            .toLowerCase() ===
                                                                                                        "package"
                                                                                                            ? "flight"
                                                                                                            : quoteOption?.productType?.code
                                                                                                                .trim()
                                                                                                                .toLowerCase()
                                                                                                    }
                                                                                                    className={
                                                                                                        "icon-16 margin-right flight-icon"
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="itinerary-heading text-ellipsis">
                                                                                                {itinerary?.heading}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </a>
                                                                            </div>
                                                                        </Tooltip>
                                                                        <div className="view-quote-available-unavailable">
                                                                            {itinerary?.status?.toLowerCase() ===
                                                                              CONSTANTS.PENDING.toLowerCase() ||
                                                                            itinerary?.status?.toLowerCase() ===
                                                                              CONSTANTS.UNAVAILABLE.toLowerCase() ? (
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            itinerary?.isAvailable
                                                                                                ? this.handleChange(
                                                                                                    quoteOption,
                                                                                                    itinerary,
                                                                                                    CONSTANTS.AVAILABLE
                                                                                                )
                                                                                                : null
                                                                                        }
                                                                                        className={
                                                                                            itinerary?.isAvailable
                                                                                                ? "available-container"
                                                                                                : "unavailable-container"
                                                                                        }
                                                                                    >
                                                                                        <div className="available-unavailable-icon">
                                                                                            <Icon
                                                                                                name={
                                                                                                    itinerary?.isAvailable
                                                                                                        ? "checked--n"
                                                                                                        : "close--n"
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>&nbsp;</div>
                                                                                )}
                                                                        </div>

                                                                        <div className="view-quote-active-inactive">
                                                                            <Switch
                                                                                checked={itinerary?.isActive}
                                                                                onChange={() =>
                                                                                    this.handleChange(
                                                                                        quoteOption,
                                                                                        itinerary,
                                                                                        CONSTANTS.ACTIVE
                                                                                    )
                                                                                }
                                                                                name="activeOrInactive"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="view-quote-product-itinerary">
                                                    {
                                                        <div className="row text-ellipsis margin-bottom-1x">
                                                            <div className="row">
                                                                <div className="product-itinerary">
                                                                    <a
                                                                        onClick={() =>
                                                                            this.onViewQuoteItinerary(
                                                                                quote?.data?.quoteNumber,
                                                                                quoteOption?.deviationItinerary
                                                                                    ?.itineraryID,
                                                                                quoteOption?.productType?.code
                                                                            )
                                                                        }
                                                                        className="link-button"
                                                                    >
                                                                        {quoteOption?.deviationItinerary?.status !== "" ? (
                                                                            <div className="row d-flex">
                                                                                <div className="margin-right itinerary-number">
                                                                                    {
                                                                                        quoteOption?.deviationItinerary
                                                                                            ?.itineraryNumber
                                                                                    }
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        quoteOption?.deviationItinerary?.status.toLowerCase() +
                                                                                      " itinerary-status margin-right"
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        quoteOption?.deviationItinerary
                                                                                            ?.status
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    <Icon
                                                                                        name={
                                                                                            quoteOption?.productType?.code
                                                                                                .trim()
                                                                                                .toLowerCase() === "package"
                                                                                                ? "flight"
                                                                                                : quoteOption?.productType?.code
                                                                                                    .trim()
                                                                                                    .toLowerCase()
                                                                                        }
                                                                                        className={
                                                                                            "icon-16 margin-right flight-icon"
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="itinerary-heading text-ellipsis">
                                                                                    {
                                                                                        quoteOption?.deviationItinerary
                                                                                            ?.heading
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="row d-flex">
                                                                                <div className="margin-right itinerary-number">
                                                                                    {
                                                                                        quoteOption?.deviationItinerary
                                                                                            ?.itineraryNumber
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    <Icon
                                                                                        name={
                                                                                            quoteOption?.productType?.code
                                                                                                .trim()
                                                                                                .toLowerCase() === "package"
                                                                                                ? "flight"
                                                                                                : quoteOption?.productType?.code
                                                                                                    .trim()
                                                                                                    .toLowerCase()
                                                                                        }
                                                                                        className={
                                                                                            "icon-16 margin-right flight-icon"
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="itinerary-heading text-ellipsis">
                                                                                    {
                                                                                        quoteOption?.deviationItinerary
                                                                                            ?.heading
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </a>
                                                                </div>
                                                                <div className="view-quote-available-unavailable">
                                                                    {quoteOption?.deviationItinerary?.status?.toLowerCase() ===
                                                                        CONSTANTS.PENDING.toLowerCase() ||
                                                                      quoteOption?.deviationItinerary?.status?.toLowerCase() ===
                                                                        CONSTANTS.UNAVAILABLE.toLowerCase() ? (
                                                                            <div
                                                                                onClick={() =>
                                                                                    quoteOption?.deviationItinerary
                                                                                        ?.isAvailable
                                                                                        ? this.handleChange(
                                                                                            quoteOption,
                                                                                            quoteOption?.deviationItinerary,
                                                                                            CONSTANTS.AVAILABLE
                                                                                        )
                                                                                        : null
                                                                                }
                                                                                className={
                                                                                    quoteOption?.deviationItinerary
                                                                                        ?.isAvailable
                                                                                        ? "available-container"
                                                                                        : "unavailable-container"
                                                                                }
                                                                            >
                                                                                <div className="available-unavailable-icon">
                                                                                    <Icon
                                                                                        name={
                                                                                            quoteOption?.deviationItinerary
                                                                                                ?.isAvailable
                                                                                                ? "checked--n"
                                                                                                : "close--n"
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div>&nbsp;</div>
                                                                        )}
                                                                </div>

                                                                <div className="view-quote-active-inactive">
                                                                    <Switch
                                                                        checked={
                                                                            quoteOption?.deviationItinerary?.isActive
                                                                        }
                                                                        onChange={() =>
                                                                            this.handleChange(
                                                                                quoteOption,
                                                                                quoteOption?.deviationItinerary,
                                                                                CONSTANTS.ACTIVE
                                                                            )
                                                                        }
                                                                        name="activeOrInactive"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* ))} */}
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            

                                            <Confirmation
                                                isShow={isShow}
                                                isButtonDisabled={isButtonDisabled}
                                                data={{
                                                    quote,
                                                    selectedData,
                                                    selectedItinerary,
                                                    selectedAction
                                                }}
                                                onSubmit={(value) => this.onSubmit(value)}
                                                onCancel={(value) => this.onCancel(value)}
                                            />
                                            
                                            <div
                                                className="view-quote-clone-option"
                                                onClick={() =>
                                                    this.setClone(quote?.data?.quoteNumber, quoteOption)
                                                }
                                            >
                                                <div className="clone-wrapper">
                                                    <div className="clone-container">
                                                        <div className="clone-icon">
                                                            <Icon name="arrow-right" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Section>
                        {isClone ? (
                            <QuoteModal
                                data={quote?.data}
                                cloneData={cloneData}
                                cloneType={cloneType}
                                isClone={isClone}
                                isShow={isClone}
                                cloneIndex={cloneIndex}
                                handleModal={() => this.closeMOdal(false)}
                            />
                        ) : null}
                        <Section className="print-hide">
                            <div className="view-quote-add-row">
                                {(isIQMUser || quoteOptions?.length < 5) ? <AddQuoteOption data={quote?.data} /> : null}                  
                            </div>
                        </Section>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    quoteItinerary: state.quoteItinerary
});

export default connect(mapStateToProps)(withRouter(ViewGroupQuote));
