import React, { useEffect } from "react";
import { Route, Router } from "wouter";
import { BrowserRouter } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { LoginCallback } from "@okta/okta-react";

import ViewQuote from "./modules/viewQuote/viewQuote";
import CreateQuote from "./modules/createQuote/createQuote";
import GroupQuoteView from "./modules/groupQuote/view/groupQuoteView";
import ReviewGroupQuotes from "./modules/reviewGroupQuotes/reviewGroupQuotes";
import ViewQuoteItinerary from "./modules/viewQuoteItinerary/viewQuoteItinerary";

import SignIn from "./modules/okta/signin";

import getProfile from "services/profile/profile.service";
import getApplicationMessages from "services/contentful/contentful.service";
import PrivateRoute from "utilities/privateRoute";
import GroupContract from "components/groupContract/groupContract";
import ViewItineraryDeviation from "modules/viewItineraryDeviation/vewItineraryDeviation";
import Container from "components/groupContract/Container";
import GenerateQuote from "components/common/generateQuote/generateQuote";
import inVoice from "components/common/inVoice/inVoice";
import ViewInvoice from "modules/viewInvoice/viewInvoice";
import { connect } from "react-redux";
import commonUtilities from "utilities/common";
import getSfdc from "services/sfdc/sfdc.service";
import { useLocation } from "wouter";

const Routes = (props) => {
    const { authState, oktaAuth } = useOktaAuth();

    // call location hook to get current route
    const setLocation = useLocation();

    const authDetect = async () => {
        try
        {
            if(await oktaAuth.session.exists()) {
                // get token
                let response = await oktaAuth.token.getWithoutPrompt();
                //console.log(response);
                //set tokens
                oktaAuth.tokenManager.setTokens(response.tokens);
                if (!oktaAuth.isLoginRedirect()) {
                    // Trigger an initial authState change event when the app startup
                    oktaAuth.authStateManager.updateAuthState();

                    props.dispatch(getProfile()).then((response) => {
                        if(commonUtilities.isIQMUser()) {
                            const currentRoute = setLocation[0];
                            if (currentRoute && currentRoute?.includes("lead")) {
                                let currentRouteSegs = currentRoute?.split("/");
                                let leadNumber = currentRouteSegs[2];
                                if (leadNumber && leadNumber?.length > 0) {
                                    props.dispatch(getSfdc(leadNumber)).then(
                                        () => {
                                            loadMessages();
                                        }
                                    );
                                }
                            }
                        }
                        else {
                            loadMessages();
                        }
                    });
                }
            }
        }
        catch(err) {
            console.error(err); 
        }
    };

    const loadMessages = () => {
        const languageCode = commonUtilities.getLanguage();
        props.dispatch(getApplicationMessages(languageCode));
    };

    useEffect(() => {
        authDetect();
    }, []);


    const { isLoading } = props;

    return (
        <BrowserRouter>

            <div id="qma-container">

                <Router>
                    <Route exact path="/" component={SignIn} />
                    <PrivateRoute exact path="/home" component={() => <CreateQuote/>} />
                    <PrivateRoute path="/review" component={ReviewGroupQuotes} />
                    <PrivateRoute path="/quote/:quoteNumber" component={ViewQuote} />
                    <PrivateRoute path="/:type/quote/:quoteNumber" component={ViewQuote} />
                    <PrivateRoute path="/lead/:leadNumber/groupContract" component={Container} />
                    <PrivateRoute path="/lead/:leadNumber/generateQuote/:quoteNumber" component={GenerateQuote} />
                    <PrivateRoute exact path="/lead/:leadNumber/inVoice" component={inVoice} />
                    <PrivateRoute exact path="/lead/:leadNumber/ViewInvoice" component={ViewInvoice} />
                    <PrivateRoute exact path="/lead/:leadNumber/quote" component={CreateQuote} />
                    <PrivateRoute path="/lead/:leadNumber/quote/:quoteNumber" component={GroupQuoteView} />
                    <PrivateRoute path="/lead/:leadNumber/quote/:quoteNumber/itinerary/:itineraryID" component={ViewQuoteItinerary} />
                    <PrivateRoute path="/lead/:leadNumber/quote/:quoteNumber/itinerary/deviation/:itineraryID" component={ViewItineraryDeviation} />                            
                    <PrivateRoute path="/group" component={GroupContract} />                           
                    <Route path="/login/callback" component={LoginCallback} />
                </Router>
                
                {isLoading ? <div className="root-loader">
                    <object className="loader-image" id="svgLoader" data="https://deploy-preview-7611--swg-epackages.netlify.app/assets/images/loader.min.svg" type="image/svg+xml"></object>
                </div> : null}
                
            </div>
        </BrowserRouter>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.isLoaderSpinning,
    profile: state.profile
});

export default connect(mapStateToProps)(Routes);
