import React, { useEffect, useRef, useState } from "react";
import { Button, FormHelperText, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import bookingInVoice from "services/invoice/invoice.service";
import { Localize } from "utilities/localize";
import "./viewInvoice.css";

//import html2pdf from "html2pdf.js";


function ViewInvoice() {
    const [isInvoice, setIsInvoice] = useState(false);
    const [isBookingDetail, setIsBookingDetail] = useState(false);
    const [isPayment, setIsPayment] = useState(false);

    const { inVoice } = useSelector(state => state);
    const { sfdc } = useSelector(state => state);

    const dispatch = useDispatch();

    // const pdfPreviewref = useRef(null);
    const [pdfData, setPdfData] = useState("");
    
    const [emailSendFailed, setEmailSendFailed] = useState(false);
    const [emailSendSuccessful, setEmailSendSuccessful] = useState(false);

    const [isEmailSend, setIsEmailSend] = useState(false);

    useEffect(() => {
        handleGeneratePdf();
    }, []);

    const handleGeneratePdf = () => {
        
        setIsInvoice(inVoice?.data?.data?.isInvoice);
        setIsBookingDetail(inVoice?.data?.data?.isBookingDetail);
        setIsPayment(inVoice?.data?.data?.isPayment);

        setEmailId(inVoice?.data?.data?.emailId);
        setPdfData(inVoice?.data?.data?.invoiceDocument);
        // html2pdf()
        //     .from(`<div style="padding: 50px">${inVoice?.data?.data?.invoiceDocument}</div>`)
        //     .toPdf()
        //     .get("pdf")
        //     .then((pdf) => {
        //         pdfPreviewref.current.src = pdf.output("datauristring");
        //     });
    };

    const [emailId, setEmailId] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);

    const handleEmail = (event) => {
        const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        let email = event?.target?.value;
        setEmailId(email);

        setIsValidEmail(!re.test(email) ? false : true);
    };
    
    
    const closeEmailPopup = () => {
        setIsEmailSend(false);
    };



    const sendEmail = () => {
        setIsEmailSend(true);
        setEmailSendFailed(false);
        setEmailSendSuccessful(false);
        const { ttsBookingNumber, language, masterBookingNumber } = sfdc?.data?.data || "";
        let payload =
        {
            isInvoice: inVoice?.data?.data?.isInvoice ? inVoice?.data?.data?.isInvoice : isInvoice,
            isBookingDetail: inVoice?.data?.data?.isBookingDetail ? inVoice?.data?.data?.isBookingDetail : isBookingDetail,
            isPayment: inVoice?.data?.data?.isPayment ? inVoice?.data?.data?.isPayment : isPayment,
            bookings: ttsBookingNumber,
            masterBookings: masterBookingNumber,
            language: language, // English (EN) or French (FR)
            email: emailId // This will be null for this endpoint.
        };
        dispatch(bookingInVoice.getInvoiceEmail(payload)).then((response) => {
            setIsEmailSend(false);
            if(response?.payload?.data?.statusCode !== 200) {
                setEmailSendFailed(true);
            }
            else {
                setEmailSendSuccessful(true);
            }
        });
    };


    return (

        <div className="app-container view-invoice-container">

            <div className="section-header">
                <h1>
                    Group Invoice
                </h1>
            </div>
            <div>
                <div className="row">
                    <div className="row margin-bottom-1x email-container">
                        <div className="inVoice-container label">
                            <div>
                                Email Address
                            </div>
                        </div>
                        <div className="inVoice-container">
                            <OutlinedInput
                                fullWidth
                                name="emailAddress"
                                id="outlined-group-name"
                                value={emailId}
                                type="email"
                                inputProps={{
                                    autoComplete: "off"
                                }}
                                errorText="Enter Valid Email"
                                onChange={(event) => {
                                    handleEmail(event);
                                }}
                                aria-describedby="outlined-email-address-helper-text"
                            />
                        </div>

                        <div className="inVoice-container button">
                            <Button
                                disabled={emailId?.length === 0}
                                data-testid={"cancel-btn"}
                                onClick={()=> sendEmail()}
                                variant="outlined">
                                Send Email
                            </Button>
                        </div>
                    </div>
                    {
                        (isValidEmail || emailId?.length == 0) ? <div className="row"><FormHelperText className="email-error-message email-error">Please enter a valid email address</FormHelperText></div> : ""
                    }
                    {isEmailSend ? 
                        <div className="row">
                            <div className="popup-container-bg delete-modal"></div>

                            <div className="popup-container">

                                <div className="popup-body">
                                    <div className="close-popup-button" onClick={() => closeEmailPopup()}>
                                        <span className="swg-icon swg-icon-close--n icon"></span>
                                    </div>

                                    <div className="popup-heading margin-bottom-1x email-popup-container">
                                    
                                        <div className="email-logo-cover"></div>
                                        <div className="email-declaration-text">
                                            {Localize("qma-email-request")} 
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div> : null}
                </div>

                { emailSendFailed || emailSendSuccessful ? 
                    <div className="row text-center">
                        {emailSendFailed ? <span className="email-error-message">Invoice has failed to send</span> : null}
                        {emailSendSuccessful ? <span>Invoice has been sent successfully</span> : null}
                    </div> : null
                }   
            </div>
            <div className="margin-top-1x">
                <embed
                    src={`data:application/pdf;base64,${pdfData}`}
                    type="application/pdf"
                    frameBorder="0"
                    scrolling="auto"
                    height="700px"
                    width="100%"
                ></embed>
            </div>
        </div>
    );


}
export default ViewInvoice;