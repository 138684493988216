import React from "react";
import { Icon } from "__ICON_REFERENCE__";
import { Localize } from "utilities/localize";
import GroupDetails from "../groupDetails/groupDetails";
import ProfileInformation from "../profileInformation/profileInformation";
import CreateQuoteWizard from "modules/createQuote/createQuoteWizard";
import styles from "./index.module.scss";

import { CONSTANTS } from "utilities/constants";

function QuoteModal(props) {

    const { data, isShow, handleModal, reviewQuoteIndex, isReQuote, isClone, cloneIndex, cloneData, cloneType } = props;   
    const { groupInformation, quoteNumber } = data;

    return (
        <div className={styles.modal}>
            <div className={isShow ? "show" : "hide"}>

                <div className="popup-container-bg"></div>

                <div id="delete-modal" className="add-quote-modal popup-container quote-modal-container">
                    <span data-testid="handlemodalcheck" onClick={() => handleModal(false)}>
                        <Icon name="close--n" className="icon" />
                    </span>
                    <div className="add-quote row">
                        <h3 data-testid="no">
                            {Localize("qma-quote-number")} {quoteNumber}
                        </h3>
                        <div className="row">
                            <div className="col-half info-box">
                                <ProfileInformation group={groupInformation} isHomePage={false} />
                            </div>
                            <div className="col-half info-box">
                                <GroupDetails group={groupInformation} />
                            </div>
                        </div>
                    </div>

                    {isShow && (
                        <CreateQuoteWizard quoteNumber={quoteNumber} cloneData={cloneData} cloneType={cloneType} isClone={isClone} cloneIndex={cloneIndex} isReQuote={isReQuote} handleModal={handleModal} createOptionSource={CONSTANTS.MODAL} reviewQuoteIndex={reviewQuoteIndex} isHomePage={false} />
                    )}
                </div>

            </div>
        </div>
    );
}

export default QuoteModal;