import React, { Component } from "react";
import { LocalizeByLang } from "utilities/localize";
import commonUtilities from "utilities/common";
import "./representativeInformation.css";

class RepresentativeInformation extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            languageCode: this.props.languageCode ?? commonUtilities.getLanguage()
        };
    }

    localize = (code) => {
        return LocalizeByLang(code, this.state.languageCode);
    };
    
    render() {
        const { salesRepresentative } = this.props;
        const { emailAddress, name, phoneNumber, booking, groupName } = salesRepresentative || {};
        const forPDF = this.props.forPDF ?? false;

        return (
            <div className="representative-information-container">
                <div className="row margin-bottom">
                    <strong>{this.localize("qma-group-sales")}</strong>
                </div>
                {name && (
                    <div className="row">
                        <div className="heading">
                            {this.localize("qma-name")}
                        </div>
                        <div className="value">{name}</div>
                    </div>
                )}
                {phoneNumber && (
                    <div className="row">
                        <div className="heading">
                            {this.localize("qma-phoneNumber")}
                        </div>
                        <div className="value">{phoneNumber}</div>
                    </div>
                )}
                {emailAddress && (
                    <div className="row">
                        <div className="heading">
                            {this.localize("qma-email")}
                        </div>
                        <div className="value">
                            {forPDF ? emailAddress : 
                                <a
                                    className="link-button"
                                    href={"mailTo:" + emailAddress}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {emailAddress}
                                </a>
                            }
                        </div>
                    </div>
                )}

                {booking && (
                    <div className="row">
                        <div className="heading">
                            {this.localize("qma-booking")}#
                        </div>
                        <div className="value">{booking}</div>
                    </div>
                )}
                {groupName && (
                    <div className="row">
                        <div className="heading">
                            {this.localize("qma-group-name-type")}
                        </div>
                        <div className="value">{groupName}</div>
                    </div>
                )}
            </div>
        );
    }
}

export default RepresentativeInformation;
