import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Icon } from "__ICON_REFERENCE__";
import { Button, FormHelperText, FormLabel, OutlinedInput } from "@mui/material";
import ReactHtmlParser from "react-html-parser";

import { Localize } from "utilities/localize";

import "./email.scss";

class Email extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailId: "",
            isValidEmail: false
        };
    }

    handleEmail(event) {
        let { emailId, isValidEmail } = this.state;
        const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        emailId = event?.target?.value;
        isValidEmail = !re.test(emailId) ? false : true;
        this.setState({ emailId, isValidEmail });
    }
    clearEmail() {
        let { emailId, isValidEmail } = this.state;
        emailId = "";
        isValidEmail = false;
        this.setState({ emailId, isValidEmail });
    }


    render() {

        const { emails, isShowEmail, handleEmailCancel, handleSendEmail, handleEmailAdd, handleEmailDelete, email } = this.props;
        let { emailId, isValidEmail } = this.state;

        return (
            <div className="show">
                <div className="popup-container-bg"></div>

                <div className="popup-container email-popup">

                    <div className="popup-body">

                        <div className="popup-heading margin-bottom-2x">
                            {Localize("qma-email-quote")}
                        </div>

                        {this.props?.email?.data?.data?.emailPopUpMessage ?
                            <div className="row font-16 margin-top">
                                {ReactHtmlParser(this.props?.email?.data?.data?.emailPopUpMessage)}
                            </div>
                            :null
                        }
                        
                        {emails && emails?.length > 0 ? emails?.map((emailAddress, index) => (

                            <div key={index} className="row email-list-items">
                                <strong>{emailAddress?.email}</strong>
                                <div
                                    className="remove-email pull-right"
                                    onClick={() => handleEmailDelete(emailAddress)}>
                                    {Localize("qma-remove")}
                                </div>
                            </div>
                        )) : null}


                        <div className="row font-16 margin-top">
                            {emails?.length < 5 ?
                                <div className="col-6 email-responsive">
                                    <div className="d-flex">
                                        <div className="emailAddress-col">
                                            <FormLabel>
                                                {" "}
                                            </FormLabel>
                                            <OutlinedInput
                                                fullWidth
                                                name="emailAddress"
                                                id="outlined-group-name"
                                                value={emailId}
                                                type="email"
                                                inputProps={{
                                                    autoComplete: "off"
                                                }}
                                                errorText="Enter Valid Email"
                                                onChange={(event) => {
                                                    this.handleEmail(event);
                                                }}
                                                aria-describedby="outlined-email-address-helper-text"
                                            />
                                            {
                                                (!isValidEmail && emailId?.length > 0) ? <FormHelperText className="email-error-message"> {Localize("qma-email-error")}</FormHelperText> : ""
                                            }

                                        </div>
                                        <div className="emailAddress-action-col">
                                            <Button
                                                data-testid="add-email-button"
                                                onClick={() => { handleEmailAdd(emailId); this.clearEmail(); }}
                                                className="add-button"
                                                variant="outlined"
                                                disabled={!isValidEmail}>
                                                <Icon name="plus--n" />
                                            </Button>

                                        </div>
                                    </div>
                                </div> : null
                            }
                        </div>

                        <div className="row margin-top-3x action-buttons-responsive">

                            <Button
                                disabled={emails?.length === 0}
                                onClick={handleSendEmail}
                                className="margin-right-1x"
                                variant="contained">
                                {Localize("qma-send-email")}
                            </Button>

                            <Button
                                onClick={handleEmailCancel}
                                variant="outlined">
                                {Localize("qma-cancel")}
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
        );

    }
}
const mapStateToProps = (state) => ({
    contentfulMasterData: state.contentfulMasterData
});

export default connect(mapStateToProps)(Email);