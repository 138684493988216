import React from "react";
import { Icon } from "__ICON_REFERENCE__";
import { Localize } from "utilities/localize";

export default function NextButton(props) {
    let { data, events } = props;
    let { currentStep } = data;

    if (currentStep > 2) return;

    return (
        <div className="wizard-button pull-right" >
            <div className="next-link-button font-bold font-16" data-testid="next-button" onClick={() => {
                (currentStep === 1)
                    ? events?.isStep1DataValid(true) : events?.isStep2DataValid(true);
            }}>
                {Localize("qma-navigation-next")}<Icon name="arrow-right" className="arrow-r" />
            </div>
        </div>
    );
}

