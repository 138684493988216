import React, { Component } from "react";
import { connect } from "react-redux";
import { Autocomplete, FormLabel, MenuItem, TextField } from "@mui/material";

import getDeviationDestinations from "services/destination/destinationDeviation.service";
import getDeviationDeparture from "services/destination/departureDeviation.service";
import commonUtilities from "utilities/common";
import { Button } from "@sunwing/components";
import AddIcon from "@mui/icons-material/Add";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { Localize } from "utilities/localize";
import "./departureCityMultiSelect.scss";

class MultiSelectDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            destinationCount: 1,
            destinations: [],
            selectedValues: []
        };
    }
    componentDidMount() {
        this.getDestinations();
        const { deviationTypes, data, index } = this.props;
        const { deviationOptionIndex } = data || {};
        if (deviationTypes[deviationOptionIndex]?.deviation?.length > 0) {
            const initialSelectedValues = [];
            deviationTypes[deviationOptionIndex]?.deviation?.map((e, index) => {
                initialSelectedValues.push(e?.destination);
            });

            const destinationCount = initialSelectedValues?.filter(e => e.code !== "")?.length === 0 ? 1 : initialSelectedValues?.filter(e => e.code !== "")?.length;
            this.setState({
                selectedValues: initialSelectedValues,
                destinationCount: destinationCount
            });

            if (initialSelectedValues?.filter(e => e.code !== "")?.length > 0) {
                this.props.dispatch(getDeviationDeparture(initialSelectedValues?.filter(e => e !== null)?.map(e => ["61", "111"]?.includes(e.providerCode) ? e.code : e.providerCode))).then(response => {
                    console.log("response initial selected ", response);
                });
            }
            this.props.setDeviationCount(destinationCount);
        }

    }
 
    

    getDestinations() {
        this.props.dispatch(getDeviationDestinations()).then((response) => {
            let data = response?.payload?.data;
            if (data && data?.length > 0) {
                let { destinations } = this.state;
                destinations = data?.map(e => e.cities)?.flat();
                this.setState({ destinations });
            }

        });
    }

    handleAddDestination = () => {
        this.props.setDeviationCount(2);
        this.setState({
            destinationCount: 2
        });
    };

    handleRemoveDestination = () => {
        const { deviationTypes, handleStep2DeviationData, deviationHotels, deviationCruises, setDeviationCruises,  setDeviationHotels, onDestinationChange, data } = this.props;
        const { deviationOptionIndex } = data || {};
        const { selectedValues } = this.state;

        let defaultDestination = { providerCode: "", code: "", name: "" };
        let selectedCities = structuredClone(selectedValues);
        let deviationTypesClone = structuredClone(deviationTypes);

        selectedCities[1] = defaultDestination;
        deviationTypesClone[deviationOptionIndex].deviation[1].destination = defaultDestination;
        deviationTypesClone[deviationOptionIndex].deviation[1].products = [];

        handleStep2DeviationData(deviationTypesClone);
        onDestinationChange(selectedCities);

        
        this.props.setDeviationCount(1);
        
        this.setState({
            selectedValues: selectedCities,
            destinationCount: 1
        });

    };
    saveDestinations = () => {
        let { selectedValues } = this.state;
        let { handleStep2Data, onDestinationChange } = this.props;
        let productType = commonUtilities.getProductType(this.props);

        let selectedCities = [];

        if (selectedValues?.length > 0) {
            selectedValues.map(city => {
                selectedCities.push(city.providerCode);
            });

            this.props.dispatch(getDeviationDeparture(selectedCities)).then(response => {
                console.log("response ", response);
            });

        }

    };

    handleData = (event, city, index) => {
        const { deviationTypes, handleStep2DeviationData, deviationHotels, deviationCruises, setDeviationHotels,onDestinationChange, data } = this.props;
        const { deviationOptionIndex } = data || {};
        const { selectedValues } = this.state;

        let selectedCities = structuredClone(selectedValues);
        let deviationTypesClone = structuredClone(deviationTypes);

        selectedCities.splice(index, 1, city);
        deviationTypesClone[deviationOptionIndex].deviation[index].destination = city;
        deviationTypesClone[deviationOptionIndex].deviation[index].products = [];
 
        this.setState({
            selectedValues: selectedCities
        });

        handleStep2DeviationData(deviationTypesClone);
        onDestinationChange(selectedCities);
    };

    render() {
        let { citiesMasterData, error } = this.props;
        const { destinationCount, selectedValues, destinations } = this.state;
        let productType = commonUtilities.getProductType(this.props);

        if (!productType) return;

        let selectPlaceHolder = Localize("qma-select-label");

        return (
            <div className="row multi-select">
                <FormLabel data-testid="label-destination">
                    {Localize("qma-destination")} (Add up to max 2 by clicking on +)
                </FormLabel>
                {
                    Array.from({ length: destinationCount }, (element, index) => index)?.map((destination, index) => (
                        <div className={`row ${index > 0 ? "margin-top-1x" : ""}`} key={index}>
                            <div className="col-6">
                                <Autocomplete
                                    data-testid="autocomplete-destination"
                                    autoHighlight
                                    value={selectedValues[index] ? selectedValues[index] : ""}
                                    isOptionEqualToValue={(option) =>
                                        option.name === selectedValues[index]?.name
                                    }
                                    noOptionsText="Select"
                                    onChange={(event, city) => this.handleData(event, city, index)}
                                    options={destinations?.length > 0 ? destinations : []}
                                    getOptionLabel={(city) =>
                                        city?.providerCode ? `${city.name} (${city.providerCode})` : ""
                                    }
                                    renderOption={(props, city, index) => (
                                        <MenuItem {...props} keys={index}>
                                            {city.name} ({city.providerCode})
                                        </MenuItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder={selectPlaceHolder}
                                            className={(error?.trim().length === 0) ? "formControl" : "errorControl formControl"}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                            {destinationCount < 2 ? (
                                <div className="col-1">
                                    <Button onClick={this.handleAddDestination} variant="contained">
                                        <AddIcon />
                                    </Button>
                                </div>
                            ) : (index === destinationCount - 1) ? <div className="col-1">
                                <Button onClick={this.handleRemoveDestination} variant="contained">
                                    <DoNotDisturbOnOutlinedIcon />
                                </Button>
                            </div> : null
                            }
                        </div>
                    ))
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    citiesMasterData: state.citiesMasterData
});

export default connect(mapStateToProps)(MultiSelectDropdown);