import React, { Component } from "react";
import { Autocomplete, FormHelperText, OutlinedInput, TextField } from "@mui/material";
import { CONFIGURATION } from "utilities/configuration";

import commonUtilities from "utilities/common";
import { Localize } from "utilities/localize";
import validateQuote from "modules/createQuote/validateQuote";

class RoomCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customRoomType: ""
        };
    }

    handleCustomRoomTypeInput = (event) => {
        this.setState({ customRoomType: event.target.value });
    };

    handleData = (event, isCustomRoomNameHandler, room) => {
        let { productIndex, roomTypeIndex, productType, events, errors } = this.props;
        let { handleStep2Data, onRoomTypeChange } = events;
        let { products } = productType;
        let product = products[productIndex];
        let { roomTypes } = product;
        let roomType = roomTypes[roomTypeIndex];

        if(room) {
            roomType.code = (room) ? room.code : "";
            roomType.name = (room) ? room.name : "";
            roomType.url = (room) ? room.url : "";
        }
        else {
            if(isCustomRoomNameHandler && roomType.code === CONFIGURATION.CUSTOM_ROOM_TYPE_CODE) {
                roomType.customName = event.target.value;
            }
        }

        let index = (roomTypeIndex + 1);
    
        if (!isCustomRoomNameHandler && (!room || room?.code === "")) {
            if (index < roomTypes.length) {

                for (let rIndex = index; rIndex < roomTypes.length; rIndex++) {
                    roomTypes[rIndex].code = "";
                    roomTypes[rIndex].name = "";
                    roomTypes[rIndex].customName = "";
                    roomTypes[rIndex].url = "";
                    roomTypes[rIndex].isDisabled = true;
                }
            }
        } else {
            errors.roomTypes[productIndex] = "";
            if(roomType.customName !== "") {
                errors.customRoomTypes[productIndex] = "";
            }
            if(!isCustomRoomNameHandler) {
                if (index < roomTypes.length) {
                    roomTypes[index].isDisabled = false;
                }
            }
        }

        onRoomTypeChange(errors);
        handleStep2Data({ ...productType });
    };

    filterMasterRooms(products){

        let selectedRooms = [];
        products?.map(product => {
            product?.roomTypes?.map(room => {
                
                if(room?.code !== ""){
                    selectedRooms.push(product?.code +"-"+ room?.code);
                }
            });
        });
        return selectedRooms;
    }

    

    render() {

        let { productType, productIndex, roomTypeIndex, roomType, disabled, error, errors } = this.props;
        let { products } = productType;
        let product = products[productIndex];
       

        let selectPlaceHolder = Localize("qma-select-label");
        let roomPlaceHolder = Localize((productType?.productType?.code.toLowerCase() === "cruise") ? "qma-cabin" : "qma-room-type");
        let choicePlaceHolder = Localize("qma-choice");

        let { masterRoomTypes, roomTypes } = product;
        let indexText = commonUtilities.getNumberByLanguage(roomTypeIndex);
        
        let selectedRooms = this.filterMasterRooms(products);

        let { customRoomType } = this.state; 
        if(roomType?.customName) {
            customRoomType = roomType?.customName;
        }
        
        let customRoomTypeError = roomType.code === CONFIGURATION.CUSTOM_ROOM_TYPE_CODE ? errors?.customRoomTypes[productIndex] : null;
        
        return (
            <>
                <div className="row row-height room-category-wrapper">
                    {roomPlaceHolder + " - " + (roomTypeIndex + 1) + indexText + " " + choicePlaceHolder}
                    <Autocomplete
                        disabled={disabled}
                        autoHighlight
                        value={roomType}
                        isOptionEqualToValue={(option) =>
                            option.name === roomType?.name
                        }
                        onChange={(event, roomType) => this.handleData(event, false, roomType)}
                        getOptionLabel={(roomType) => (roomType ? roomType.name : "")}
                        options={masterRoomTypes?.length > 0 ? masterRoomTypes?.filter(
                            (room) =>
                                !selectedRooms?.map((selectedRoom) => selectedRoom).includes(product?.code+"-"+room.code)
                        ): []}
                        renderOption={(props, roomCategory, index) => (
                            <li {...props} keys={index}>
                                {roomCategory.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                placeholder={selectPlaceHolder}
                                className={(!error) ? "formControl" : "errorControl formControl"}
                                    
                                {...params}
                            />
                        )}
                    />
                </div>
                {roomType?.code === CONFIGURATION.CUSTOM_ROOM_TYPE_CODE ? <div className="row row-height margin-top-1x">
                    {roomPlaceHolder + " - " + (roomTypeIndex + 1) + indexText + " " + "Custom name"}
                    <div>
                        <OutlinedInput
                            fullWidth
                            inputProps={{ maxLength: 255 }}
                            name="customRoomType"
                            id="outlined-group-name"
                            value={customRoomType}
                            onChange={(event) => {
                                this.handleCustomRoomTypeInput(event);
                            }
                            }
                            onBlur={
                                (event) => {
                                    this.handleData(event, true);
                                    validateQuote.isCustomRoomTypeValid(event.target.value, errors, productIndex);
                                }
                            } // Handle blur event
                            aria-describedby="outlined-custom-room-type-helper-text"
                            error={customRoomTypeError?.trim().length > 0}
                        />
                        <FormHelperText error={customRoomTypeError?.trim().length > 0}>
                            {customRoomTypeError?.trim().length > 0 && (
                                customRoomTypeError
                            )}
                        </FormHelperText>
                    </div>
                </div> : null}
            </>
        );
    }
}

export default RoomCategory;