export const CONFIGURATION = {
    IS_OFFLINE_MODE: false,
    CONTACT_TYPE: "AGENT",
    CUSTOMER_TYPE: "RETAIL",
    SEARCH_ALIAS: process.env.REACT_APP_SEARCH_ALIAS.toLowerCase(),
    SEARCH_BRAND_CODE: process.env.REACT_APP_BRAND.toUpperCase(),
    CUSTOM_ROOM_TYPE_CODE: "CUSTOM",
    DATE_FORMAT: "DD MMM YYYY",
    DATE_FORMAT_WITH_TIME: "MMM DD, YYYY H:mm",
    DUE_DATE_FORMAT: "DD-MMM-YY",
    DAYS: "days",
    DATE_FORMAT_TIME: "DD-MM-YYYY hh:mm:ss",
    LOADING_SCREEN: 3000,
    MINIMUM_VACATION_DAYS: 8,
    NUMBER_OF_PRODUCTS: 3,
    NUMBER_OF_PRODUCTS_IQM: 10,
    NUMBER_OF_ROOM_TYPES: 3,
    PAGE_RECORD_SIZE: 10,
    PAGE_INDEX: 1
};
