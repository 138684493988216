import React, { useEffect, useState } from "react";
import { Box, FormHelperText, FormLabel, OutlinedInput } from "@mui/material";
import DateRangePicker from "../../../../date-picker/date-range-picker";
import Departure from "./departure/departure";
import ProductTypeOption from "./productTypeOption/productTypeOption";
import Destination from "./destination/destination";
import HotelDetails from "./hotelDetails/hotelDetails";
import DeviationHotel from "./deviationHotel/hotel";
import commonUtilities from "utilities/common";

import { Localize } from "utilities/localize";
import { CONSTANTS } from "utilities/constants";
import MultiSelectDropdown from "components/common/multiSelectAutocomplete/multiSelect";
import MultiSelectDepartures from "components/common/multiSelectAutocomplete/multiSelectDepartures";

export default function CreateQuoteStepTwo(props) {

    const nightText = Localize("qma-night");
    const [deviationCount, setDeviationCount] = useState(1);

    const [deviationCabins, setDeviationCabins] = useState([]);

    const [isDurationHidden, setDurationHidden] = useState(false);


    const { data, events, errors } = props;
    const { quote, currentStep, optionIndex, createOptionSource, 
        currentRoute, quoteData, quotesData, reviewQuoteIndex, isReQuote, isEdit, clearSelectedDate,flightsDates,deviationCruises,deviationHotels,deviationOptionIndex } = data;
    let productType = commonUtilities.getProductType(props);
    const isIQMUser = commonUtilities.isIQMUser();

    const duration = commonUtilities.calculateDuration(
        productType?.departureDate,
        productType?.returnDate
    );
    if (currentStep !== 2) {
        return null;
    }

    const renderOptionIndex = () => {
        if (createOptionSource && createOptionSource === CONSTANTS.MODAL && !isReQuote) {
            return currentRoute.split("/").includes(CONSTANTS.QUOTE)
                ? optionIndex + quoteData?.data?.quoteOptions?.length + 1
                : optionIndex + quotesData?.data?.quotes[reviewQuoteIndex]?.quoteOptions?.length + 1;
        } else if (createOptionSource && createOptionSource === CONSTANTS.MODAL && isReQuote) {
            return currentRoute.split("/").includes(CONSTANTS.QUOTE)
                ? optionIndex + quoteData?.data?.quoteOptions?.length
                : optionIndex + 1;
        }

        return optionIndex + 1;
    };

    return (
        <div className="row step-two">

            <div className="row option-heading margin-bottom-3x ">
                <div className="pull-left margin-right">
                    {Localize("qma-option")}
                </div>

                <div className="pull-left">{renderOptionIndex()}</div>
            </div>

            <div className="row">
                <ProductTypeOption
                    productType={quote?.productTypes[optionIndex]}
                    deviationType={quote?.deviationTypes[deviationOptionIndex]}
                    isManualQuote={quote?.productTypes[optionIndex]?.isManualQuote}
                    handleStep2Data={events.handleStep2Data}
                    getDestinations={events.getDestinations}
                    resetStep2={events.resetStep2}
                    isClone={data?.isClone}
                    isEdit={isEdit}
                />
            </div>
            {productType?.productType?.code === "Deviation" ? (
                <>
                    <div className="row margin-top-3x responsive">
                        <div className="col-5 x-full">
                            <MultiSelectDropdown
                                deviationTypes={quote?.deviationTypes}
                                data={data}
                                error={errors?.destination}
                                handleStep2Data={events.handleStep2Data}
                                handleStep2DeviationData={events.handleStep2DeviationData}
                                onDestinationChange={events.onDestinationChange}
                                setDeviationCount={setDeviationCount}
                                deviationHotels={deviationHotels}
                                deviationCruises={deviationCruises}
                            />
                            <FormHelperText error={errors?.deviationError?.destination}>
                                {errors?.deviationError?.destination?.trim()?.length > 0 &&
                                    errors?.deviationError?.destination}
                            </FormHelperText>
                        </div>

                        <div className="col-5 x-full">
                            <MultiSelectDepartures
                                deviationTypes={quote?.deviationTypes}
                                data={data}
                                error={errors?.destination}
                                handleStep2Data={events.handleStep2Data}
                                handleStep2DeviationData={events.handleStep2DeviationData}
                                onDestinationChange={events.onDestinationChange}
                            />
                            <FormHelperText error={errors?.deviationError?.departure}>
                                {errors?.deviationError?.departure?.trim()?.length > 0 &&
                                    errors?.deviationError?.departure}
                            </FormHelperText>
                        </div>
                    </div>
                    <div className="row margin-top">
                        <div className="col-5 x-full">
                            <DateRangePicker
                                data={data} 
                                errors={errors}
                                clearDateEvent={clearSelectedDate}
                                handleStep2DeviationDates={events.handleStep2DeviationDates} 
                                handleStep2Data={events.handleStep2Data}
                                setDurationHidden={setDurationHidden}
                                isDisabled={ !quote?.deviationTypes[deviationOptionIndex]?.departures?.length > 0}
                                onDateRangeChange={events.onDateRangeChange}
                            />
                        </div>

                        <div className="col-3">
                            <div className="row">
                                <div className="col-5 x-full">
                                    <div className="row">
                                        <FormLabel>
                                            {Localize("qma-number-of-adults")}
                                        </FormLabel>
                                    </div>
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        data-testid="textinput-noOfAdults"
                                        name="noOfAdults"
                                        id="outlined-total-number-of-adults"
                                        value={quote?.deviationTypes[deviationOptionIndex]?.noOfAdults}
                                        inputProps={{ maxLength: 3 }}
                                        onChange={(event) => {
                                            const re = /^[0-9\b]+$/;
                                            if (event.target.value === "" || re.test(event.target.value)) {
                                                quote.deviationTypes[deviationOptionIndex].noOfAdults = event.target.value;
                                                events.handleStep2DeviationData(quote.deviationTypes);
                                            }
                                            events.onDevationNoOfAdultChange();
                                        }}
                                        error={errors?.noOfAdults?.trim().length > 0}
                                        aria-describedby="outlined-total-number-of-adults"
                                    />
                                    <FormHelperText error={errors?.noOfAdults}>
                                        {errors?.noOfAdults?.trim().length > 0 &&
                                            errors?.noOfAdults}
                                    </FormHelperText>
                                </div>

                                <div className="col-5-no-margin-r x-full">
                                    <div className="row">
                                        <FormLabel>
                                            {Localize("qma-number-of-children")}
                                        </FormLabel>
                                    </div>
                                    <OutlinedInput
                                        type="text"
                                        name="noOfChildren"
                                        id="outlined-total-number-of-children"
                                        value={quote?.deviationTypes[deviationOptionIndex]?.noOfChildren}
                                        inputProps={{ maxLength: 3 }}
                                        onChange={(event) => {
                                            const re = /^[0-9\b]+$/;
                                            if (event.target.value === "" || re.test(event.target.value)) {
                                                quote.deviationTypes[deviationOptionIndex].noOfChildren = event.target.value;
                                                events.handleStep2DeviationData(quote.deviationTypes);
                                            }
                                        }}
                                        aria-describedby="outlined-total-number-of-children"
                                    />
                                </div>
                            </div>
                        </div>


                        
                    </div>
                    <div className="row margin-top-3x">
                        {Array.from({ length: deviationCount }, (element, index) => index)?.map((item, index) => (
                            <div className={`${index > 0 ? "row margin-top-2x" : "row"}`} key={index}>
                                <div className={`${index > 0 ? "col-2 margin-top-2x" : "col-2"}`}>
                                    <DeviationHotel
                                        index={0 + index * 4}
                                        deviationIndex={index}
                                        deviationTypes={quote?.deviationTypes}
                                        deviationHotels={deviationHotels?.[index]?.length > 0 ? deviationHotels[index] : deviationHotels?.length > 0 ? deviationHotels : []}
                                        setDeviationCabins={setDeviationCabins}
                                        deviationCabins={deviationCabins}
                                        deviationCruises={deviationCruises}
                                        handleStep2DeviationData={events.handleStep2DeviationData}
                                        deviationProductType={"Hotel"}
                                        roomType="Room"
                                        data={data}
                                        events={events}
                                        title={"Hotel"}
                                        error={errors && errors?.product}
                                        errors={errors}
                                    />
                                </div>
                                <div className={`${index > 0 ? "col-2 margin-top-2x" : "col-2"}`}>
                                    <DeviationHotel
                                        index={1 + index * 4}
                                        deviationIndex={index}
                                        deviationTypes={quote?.deviationTypes}
                                        deviationHotels={deviationHotels?.[index]?.length > 0 ? deviationHotels[index] : deviationHotels?.length > 0 ? deviationHotels : []}
                                        setDeviationCabins={setDeviationCabins}
                                        deviationCabins={deviationCabins}
                                        deviationCruises={deviationCruises}
                                        handleStep2DeviationData={events.handleStep2DeviationData}
                                        deviationProductType={"Hotel"}
                                        roomType="Room"
                                        data={data}
                                        events={events}
                                        title={"Hotel"}
                                        error={errors && errors?.product}
                                        errors={errors}
                                    />

                                </div>
                                <div className={`${index > 0 ? "col-2 margin-top-2x" : "col-2"}`}>
                                    <DeviationHotel
                                        index={2 + index * 4}
                                        deviationIndex={index}
                                        deviationTypes={quote?.deviationTypes}
                                        deviationCruises={deviationCruises?.[index]?.length > 0 ? deviationCruises[index] : deviationCruises.length > 0 ? deviationCruises : []}
                                        setDeviationCabins={setDeviationCabins}
                                        deviationCabins={deviationCabins}
                                        handleStep2DeviationData={events.handleStep2DeviationData}
                                        deviationProductType={"Cruise"}
                                        roomType="Cabin"
                                        data={data}
                                        events={events}
                                        title={"Cruise"}
                                        error={errors && errors?.product}
                                        errors={errors}
                                    />


                                </div>
                                <div className={`${index > 0 ? "col-2 margin-top-2x" : "col-2"}`}>
                                    <DeviationHotel
                                        index={3 + index * 4}
                                        deviationIndex={index}
                                        deviationTypes={quote?.deviationTypes}
                                        deviationCruises={deviationCruises?.[index]?.length > 0 ? deviationCruises[index] : deviationCruises.length > 0 ? deviationCruises : []}
                                        setDeviationCabins={setDeviationCabins}                                        
                                        deviationCabins={deviationCabins}
                                        handleStep2DeviationData={events.handleStep2DeviationData}
                                        deviationProductType={"Cruise"}
                                        roomType="Cabin"
                                        data={data}
                                        events={events}
                                        title={"Cruise"}
                                        error={errors && errors?.product}
                                        errors={errors}
                                    />


                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className="row margin-top-3x responsive">
                    <div className="row">
                        <div className="col-3">
                            <Destination
                                data={data}
                                error={errors?.destination}
                                handleStep2Data={events.handleStep2Data}
                                onDestinationChange={events.onDestinationChange}
                            />
                            <FormHelperText error={errors?.destination}>
                                {errors?.destination?.trim().length > 0 &&
                                    errors?.destination}
                            </FormHelperText>
                        </div>
                        {productType?.productType?.code !== "Hotel" &&
                            (
                                <div className="col-3">
                                    <Departure
                                        data={data}
                                        error={errors?.departure}
                                        handleStep2Data={events.handleStep2Data}
                                        onDepartureChange={events.onDepartureChange}
                                    />
                                    <FormHelperText error={errors?.departure}>
                                        {errors?.departure?.trim().length > 0 &&
                                            errors?.departure}
                                    </FormHelperText>
                                </div>
                            )}

                        {productType?.productType?.code !== "Hotel" && (
                            <div className="col-3">
                                    &nbsp;
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-5 x-full">
                            <Box>
                                <DateRangePicker width="100%"
                                    data={data}
                                    errors={errors}
                                    clearDateEvent={clearSelectedDate}
                                    handleStep2Data={events.handleStep2Data} 
                                    onDateRangeChange={events.onDateRangeChange}
                                    setDurationHidden={setDurationHidden}
                                    isDisabled={ productType?.destination?.code === "" || (productType?.productType?.code !== "Hotel" && productType?.departure.code === "")  || 
                                    (productType?.productType?.code !== "Hotel" && !flightsDates?.data?.departureDate && !flightsDates?.data?.returnDate) }
                                />
                                {/* <FormHelperText error={errors?.date}>
                                    {errors?.date?.trim().length > 0 &&
                                        errors?.date}  hello world
                                </FormHelperText> */}
                            </Box>
                        </div>
                        <div className="col-3 travelers-count">
                            <div className="row">
                                <div className="col-5 x-full">
                                    <div className="row">
                                        <FormLabel>
                                            {Localize("qma-number-of-adults")}
                                        </FormLabel>
                                    </div>
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        data-testid="textinput-noOfAdults"
                                        name="noOfAdults"
                                        id="outlined-total-number-of-adults"
                                        value={productType?.noOfAdults}
                                        inputProps={{ maxLength: 3 }}
                                        onChange={(event) => {
                                            const re = /^[0-9\b]+$/;
                                            if (event.target.value === "" || re.test(event.target.value)) {
                                                productType.noOfAdults = event.target.value;
                                                events.handleStep2Data(productType);
                                            }
                                            events.onNoOfAdultChange();
                                        }}
                                        error={errors?.noOfAdults?.trim().length > 0}
                                        aria-describedby="outlined-total-number-of-adults"
                                    />
                                    <FormHelperText error={errors?.noOfAdults}>
                                        {errors?.noOfAdults?.trim().length > 0 &&
                                            errors?.noOfAdults}
                                    </FormHelperText>
                                </div>

                                <div className="col-5-no-margin-r x-full">
                                    <div className="row">
                                        <FormLabel>
                                            {Localize("qma-number-of-children")}
                                        </FormLabel>
                                    </div>
                                    <OutlinedInput
                                        fullWidth
                                        type="text"
                                        name="noOfChildren"
                                        id="outlined-total-number-of-children"
                                        value={productType?.noOfChildren}
                                        inputProps={{ maxLength: 3 }}
                                        onChange={(event) => {
                                            const re = /^[0-9\b]+$/;
                                            if (event.target.value === "" || re.test(event.target.value)) {
                                                productType.noOfChildren = event.target.value;
                                                events.handleStep2Data(productType);
                                            }
                                        }}
                                        aria-describedby="outlined-total-number-of-children"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(productType?.productType?.code.toLowerCase() !== "flight" &&
                productType?.productType?.code.toLowerCase() !== "deviation") && (
                <div className="row margin-top-1x">
                    {(productType?.productType?.code.toLowerCase() == "package" && isIQMUser) ? <div>You can select up to 10 hotels.</div> : ""}
                    <HotelDetails
                        errors={errors}
                        data={data}
                        events={events}
                    />
                </div>
            )}
        </div>
    );
}