import React, { Component } from "react";
import { Icon } from "__ICON_REFERENCE__";

import "./productTypeIcon.css";

export default class ProductTypeIcon extends Component {

    render() {
        const { productType, className } = this.props;
        return (productType === "package") ?
            <Icon data-testid="package" name="packages" className={"product-type-icon margin-right " + className} />
            : (
                (productType === "flight") ? 
                    <Icon data-testid="flight" name="flight" className={"product-type-icon margin-right " + className} />
                    :
                    (productType === "hotel") ? 
                        <Icon data-testid="hotel" name="hotel" className={"product-type-icon margin-right " + className} />
                        :
                        (productType === "cruise") ? 
                            <Icon data-testid="cruise" name="cruise" className={"product-type-icon margin-right " + className} />
                            :
                            (productType === "excursions") ? 
                                <Icon data-testid="excursions" name="excursions" className={"product-type-icon margin-right " + className} />
                                : null);
    }
}