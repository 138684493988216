import React from "react";
import { Icon } from "__ICON_REFERENCE__";
import { Localize } from "utilities/localize";

export default function PreviousButton(props) {
    let { data, events } = props;
    let { currentStep, isEdit, isAdd } = data;

    if (currentStep !== 2) return;

    return (
        <div className="wizard-button pull-left">
            <div className="next-link-button visible font-bold font-16"
                onClick={
                    isEdit || isAdd ? events?.cancel : events?.previous}
            >
                <Icon name="arrow-left" className="arrow-l" />{Localize("qma-navigation-back")}
            </div>
        </div >
    );
}